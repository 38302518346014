import dayjs from "dayjs";

export function getMissingDates(dates, year, month) {
  const dateSet = new Set(dates);
  const missingDates: string[] = [];
  const daysInMonth = dayjs(`${year}-${month}`).daysInMonth();

  for (let day = 1; day <= daysInMonth; day++) {
    const formattedDate = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
    if (!dateSet.has(formattedDate)) {
      missingDates.push(formattedDate);
    }
  }

  return missingDates;
}
