import { useAppSelector } from "slices";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import {
  USER_PROFILE_STATUS,
  USER_SELECTIONS,
} from "Components/constants/userData";

const Dashboard = () => {
  const { permissions, user, userConfig } = useAppSelector(
    (state) => state.Global
  );
  const isBoth = user.type === "BOTH";
  const isMentee = user.type === "MENTEE";
  const isMentor = user.type === "MENTOR";

  const isOrgAdmin =
    permissions.findIndex(
      (permission) => permission?.resource === "org-admin"
    ) !== -1;
  const navigate = useNavigate();

  useEffect(() => {
    if (isOrgAdmin) {
      navigate("/dashboard/admin");
    } else if (isMentee || isBoth) {
      navigate("/dashboard/mentee");
    } else if (isMentor) {
      navigate("/dashboard/mentor");
    }
    if (isEmpty(userConfig)) {
      navigate("/onboarding/upload-profile");
    } else if (!userConfig.onboarding?.[USER_PROFILE_STATUS.profile_form]) {
      navigate("/onboarding/upload-profile");
    } else if (!isOrgAdmin && !userConfig.onboarding?.[USER_SELECTIONS]) {
      navigate("/onboarding/create");
    } else if (
      !userConfig.onboarding?.[USER_PROFILE_STATUS.availability_set] &&
      userConfig.onboarding?.[USER_SELECTIONS]?.mentor
    ) {
      navigate("/onboarding/set-availability");
    } else if (
      !userConfig.onboarding?.[USER_PROFILE_STATUS.onboarding_completed] &&
      userConfig.onboarding?.[USER_SELECTIONS]?.mentor
    ) {
      navigate("/onboarding/create/mentor-form");
    } else if (
      !userConfig.onboarding?.[USER_PROFILE_STATUS.onboarding_completed] &&
      userConfig.onboarding?.[USER_SELECTIONS]?.mentee
    ) {
      navigate("/onboarding/create/mentee-form");
    }
  }, [userConfig, isOrgAdmin, isMentee, isMentor, isBoth, navigate]);

  return null;
};

export default Dashboard;
