import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  allMentees: null,
  suggestedMentees: null,
  xyzMentees: null,
  submittedMenteeSurvey: [],
};

const MenteeSlice = createSlice({
  name: "Mentee",
  initialState,
  reducers: {
      SetMentees(state, action) {
        state.allMentees = action.payload.all_mentors;
        state.suggestedMentees = action.payload.suggested_mentors;
        state.xyzMentees = action.payload.xyz_mentors;
      },
      setMenteeSubmittedSurvey(state, action) {
        state.submittedMenteeSurvey = action.payload;
      },
  },
});

export const {
  SetMentees,
  setMenteeSubmittedSurvey,
} = MenteeSlice.actions

export default MenteeSlice.reducer;
