import React, { useState } from "react";
import { Container, Button, FormGroup, Input, Form } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { uploadProfilePicture } from "slices/onboarding/thunk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import Pages404 from "pages/Utility/pages-404";
import { InfoForm } from "./InfoForm";

const UploadProfile = () => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      userConfig: global.userConfig,
      user: global.user
    })
  );
  const { userConfig, user } = useSelector(selectProperties);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: null,
    },
    validationSchema: Yup.object({
      file: Yup.mixed().required("Please select a file"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await dispatch(uploadProfilePicture(values));
      } catch (error) {
        console.error("Error uploading profile picture", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    validation.setFieldValue("file", file);
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  return (
    userConfig?.onboarding?.onboardingCompleted ? <Pages404 /> : (
      <div className="page-content" style={{ paddingTop: '50px' }}>
        <Container fluid>
          <h1 style={{ textAlign: "center", marginBottom: "10px" }}>Details</h1>
          <FormGroup style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Form onSubmit={validation.handleSubmit} style={{ width: "50%", textAlign: "center" }}>
              <Input
                type="file"
                name="file"
                accept="image/*"
                id="fileUpload"
                onChange={handleFileChange}
                onBlur={validation.handleBlur}
                style={{ width: "60%", display: "inline-block" }}
              />
              <Button
                color="secondary"
                type="submit"
                disabled={validation.isSubmitting || !validation.values.file}
                style={{ marginLeft: "10px" }}
              >
                {validation.isSubmitting ? "Uploading..." : "Upload Photo"}
              </Button>
            </Form>
          </FormGroup>
          <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "10px"}}>
            <h5>Preview:</h5>
            <div style={{ width: "50px", height: "50px", borderRadius: "50%", overflow: "hidden", margin: "auto" }}>
              {(previewImage || user?.avatarURL) && <img src={previewImage || user?.avatarURL} alt="Preview" style={{ maxHeight: "100%" }} />}
            </div>
          </div>
          <InfoForm />
        </Container>
      </div>
    )
  );
};

export default UploadProfile;
