import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { APIClient } from "../../helpers/api_helper";
import UserList from "../../Components/UserList";
import user1 from "assets/images/users/user_profile.svg";
import { toast } from "react-toastify";
import "../../assets/scss/custom/components/UserList.scss";

interface Person {
  label: string;
  value: number;
  id: string;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  department: string;
}

const api = new APIClient();

export const Suggest = () => {

  const [activeTab, setactiveTab] = useState<number>(1);
  const [mentors, setMentors] = useState<Person[]>([]);
  const [mentees, setMentees] = useState<Person[]>([]);
  const [selectedMentor, setSelectedMentor] = useState<Person | null>(null);
  const [selectedMentee, setSelectedMentee] = useState<Person | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [mentorSearchQuery, setMentorSearchQuery] = useState('');

  const [passedSteps, setPassedSteps] = useState<number[]>([1]);

  function toggleTab(tab: number) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  const fetchMentorsInit = useCallback(async () => {
    const response = await api.get('api/v1/search?filter.profileType=Mentor');
    if (response) {
      setMentors(response);
    }
  }, []);

  const fetchMenteesInit = async () => {
    const response = await api.get('api/v1/search?filter.profileType=Mentee');
    if (response) {
      setMentees(response);
    }
  };

  const handleMentorSelect = (mentor: Person) => {
    setSelectedMentor(mentor);
    fetchMenteesInit();
  };

  const handleMenteeSelect = (mentee: Person) => {
    setSelectedMentee(mentee);
  };

  const handleConfirm = async () => {
    if (selectedMentor && selectedMentee) {
      api.create(`api/v1/users/${selectedMentee.id}/suggest-mentor`, {
        mentorId: selectedMentor.id
      }).then(() => {
        toast.success('Mentor-Mentee pairing was successful');
        window.location.reload();
      }).catch((error) => {
        toast.error(error.message || `Mentor suggestion failed`);
      })
    }
  };

  useEffect(() => {
    fetchMentorsInit();
  }, [fetchMentorsInit]);

  const handleMentorSearchChange = async (event) => {
    if (event.target.value === '') {
      setMentorSearchQuery('');
      fetchMentorsInit();
    } else {
      setMentorSearchQuery(event.target.value);
      const response = await api.get(`api/v1/search?filter.name=${event.target.value}&filter.profileType=Mentor`);
      if (response) {
        setMentors(response);
      }
    }
  };

  const handleSearchChange = async (event) => {
    if (event.target.value === '') {
      setSearchQuery('');
      fetchMenteesInit();
    } else {
      setSearchQuery(event.target.value);
      const response = await api.get(`api/v1/search?filter.name=${event.target.value}&filter.profileType=Mentee`);
      if (response) {
        setMentees(response);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Pairing" breadcrumbItem="Pair Mentor-Mentee" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem className={classnames({ current: activeTab === 1 })}>
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => setactiveTab(1)}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Select Mentor
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 2 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => setactiveTab(2)}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Select Mentee
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 3 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => setactiveTab(3)}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Confirm Matching
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <div className="mb-3">
                            <input
                              type="text"
                              placeholder="Search mentors..."
                              value={mentorSearchQuery}
                              onChange={handleMentorSearchChange}
                              className="form-control user-list search-input"
                            />
                          </div>
                          <UserList
                            users={mentors}
                            onSelect={handleMentorSelect}
                            selectedUser={selectedMentor}
                            removeUser={selectedMentee}
                            userType="mentor"
                          />
                        </TabPane>
                        <TabPane tabId={2}>
                          <div className="mb-3">
                            <input
                              type="text"
                              placeholder="Search mentees..."
                              value={searchQuery}
                              onChange={handleSearchChange}
                              className="form-control user-list search-input"
                            />
                          </div>
                          <UserList
                            users={mentees}
                            onSelect={handleMenteeSelect}
                            selectedUser={selectedMentee}
                            removeUser={selectedMentor}
                            userType="mentee"
                          />
                        </TabPane>
                        <TabPane tabId={3}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">Confirm the mentor-mentee matching</p>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <img
                                      src={selectedMentor?.avatarUrl || user1}
                                      alt={selectedMentor?.id}
                                      className="rounded-circle"
                                      style={{ width: '40px', height: '40px' }}
                                    />
                                    <span className="ms-2">{selectedMentor?.firstName}</span>
                                    <span className="ms-2 me-3">🔗</span>
                                    <img
                                      src={selectedMentee?.avatarUrl || user1}
                                      alt={selectedMentee?.id}
                                      className="rounded-circle"
                                      style={{ width: '40px', height: '40px' }}
                                    />
                                    <span className="ms-2">{selectedMentee?.firstName}</span>
                                  </div>
                                  <button className="btn btn-primary mt-3" onClick={handleConfirm}>
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>

                    <div className="actions clearfix">
                      <ul>

                        <li className={activeTab === 1 ? "previous disabled" : "previous"}>
                          <Link to="#" onClick={() => {
                            if (activeTab !== 1) {
                              toggleTab(activeTab - 1);
                            }
                          }}>
                            Previous
                          </Link>
                        </li>

                        <li className={(activeTab === 1 && !selectedMentor) || (activeTab === 2 && !selectedMentee) ? "next disabled" : "next"}>
                          <Link to="#" onClick={() => {
                            if (!((activeTab === 1 && !selectedMentor) || (activeTab === 2 && !selectedMentee))) {
                              toggleTab(activeTab + 1);
                            }
                          }}>
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
