

import React, { Fragment, useState } from "react";
import { Row, Table, Button, Col } from "reactstrap";
import { flexRender, useReactTable, getCoreRowModel, getFilteredRowModel, getSortedRowModel, ColumnFiltersState } from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
interface InviteUsersTableProps {
  columns?: any;
  data?: any;
  divClassName?: any;
  tableClass?: any;
  isBordered?: boolean;
  buttonClass: string;
  buttonName: string;
  handleUserClick: any;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

const InviteUsersTable = ({
  columns,
  data,
  tableClass,
  divClassName,
  isBordered,
  buttonClass,
  buttonName,
  handleUserClick,
}: InviteUsersTableProps) => {

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const fuzzyFilter = (row: any, columnId: any, value: any, addMeta: any) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { getHeaderGroups, getRowModel } = table;

  return (
    <Fragment>
      <Row className="mb-2">
        <Col sm={12}>
          <div className="text-sm-end">
            <Button type="button" className={buttonClass} color="primary" onClick={handleUserClick}>
              <i className="mdi mdi-plus me-1" />{buttonName}
            </Button>
          </div>
        </Col>
      </Row>

      <div className={divClassName ? divClassName : "table-responsive"}>
        <Table hover className={tableClass} bordered={isBordered}>
          <caption>Invited Users</caption>
          <thead>
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan} className={`${header.column.columnDef.enableSorting ? "sorting sorting_desc" : ""}`}>
                    {header.isPlaceholder ? null : (
                      <React.Fragment>
                        <div
                          {...{
                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {
                            {
                              asc: '',
                              desc: '',
                            }
                            [header.column.getIsSorted() as string] ?? null}
                        </div>
                      </React.Fragment>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default InviteUsersTable;
