import React from "react";
import {Row, Col, Container} from "reactstrap";
import comingSoon from "../assets/images/coming-soon.svg";

const LoadingFullScreen = ({title, subtitle}) => {
  return (
    <React.Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={comingSoon}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{title}</h4>
                <p className="text-muted">
                  {subtitle}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LoadingFullScreen;
