import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import userAvatar from "../../assets/images/users/user_profile.svg";

const List = ({ listData }: any) => {
  return (
    <>
      <Row>
        {(listData || []).map((item: any, key: number) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  {item?.matchPercentage &&
                    <div className="flex-grow-1">
                      <span className="badge badge-soft-info text-uppercase">
                        {Math.round(item.matchPercentage)}% Match
                      </span>
                    </div>
                  }
                </div>
                <div className="text-center mb-3">
                  <img src={item.avatarUrl || userAvatar} alt="" className="avatar-xl rounded-circle" />
                  <h6 className="font-size-15 mt-3 mb-1">{item.firstName} {item.lastName}</h6>
                  <div>
                    <p className="badge badge-soft-info mb-0 text-muted text-capitalize me-2">{item.department}</p>
                    <p className="badge badge-soft-info mb-0 text-muted text-uppercase">{item.title}</p>
                  </div>
                </div>
                <div className="d-flex mb-1 justify-content-center gap-2 text-muted">
                  <div>
                    <i className='bx bx-map align-middle text-primary'></i> {item.location}
                  </div>
                </div>
                <div className="hstack gap-2 justify-content-center">
                  {(item.skills || []).map((subItem: any, key: number) => (
                    <span key={key} className="badge badge-soft-secondary">{subItem}</span>
                  ))}
                </div>
                <div className="mt-2 pt-1">
                  <Link
                    to={`/profile/${item.id}?viewingAs=MENTEE`}
                    className="btn btn-soft-primary d-block"
                  >
                    View Profile
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default List;
