import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import logo from "../../../assets/images/logo.png";

//Import Images
import error from "../../../assets/images/user_blocked.svg"

const UserBlocked = () => {
   document.title = "User Blocked | Mentorgain";

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
      </div>
      <div className="account-pages my-3 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">
                <div className="mb-5">
                  <Link to="/dashboard" className="d-block auth-logo" >
                    <img src={logo} alt="logo" className="auth-logo-dark mx-auto" height="60" />
                  </Link>
                  <span>Mentorgain</span>
                </div>

                <h1 className="display-5 fw-medium">
                  Uh oh
                </h1>
                <h5>Your request has been blocked by admin!</h5>
                <h6>Please contact admin for support</h6>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserBlocked
