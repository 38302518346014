import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Accordion from "./Accordion";
import { mentorFaqItems, menteeFaqItems, FaqItem } from "./FaqItems";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

interface FaqProps {
  user?: {
    type: "MENTOR" | "MENTEE" | "BOTH";
  };
}

const selectProfileProperties = createSelector(
  (state: any) => state.Global,
  (global) => ({
    user: global.user,
    permissions: global.permissions,
  })
);

const renderFaqCard = (title: string, faqItems: FaqItem[]) => (
  <Col lg="12">
    <div className="vertical-nav">
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">{title}</h4>
          {faqItems.map((item, index) => (
            <Accordion key={index} question={item.question} answer={item.answer} />
          ))}
        </CardBody>
      </Card>
    </div>
  </Col>
);

export const Faq: React.FC<FaqProps> = ({ user: userProp }) => {
  const { user } = useSelector(selectProfileProperties);
  const currentUser = userProp || user;

  return (
    <React.Fragment>
      <section className="section" id="faqs">
        <Container>
          <Row>
            {currentUser?.type === "MENTOR" && renderFaqCard("Mentor Frequently Asked Questions", mentorFaqItems)}

            {currentUser?.type === "MENTEE" && renderFaqCard("Mentee Frequently Asked Questions", menteeFaqItems)}

            {currentUser?.type === "BOTH" && (
              <>
                {renderFaqCard("Mentor Frequently Asked Questions", mentorFaqItems)}
                {renderFaqCard("Mentee Frequently Asked Questions", menteeFaqItems)}
              </>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
