import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  postFormData,
  postOnboardingCompleted,
} from "slices/onboarding/thunk";
import Spinners from "Components/Common/Spinner";
import { APIClient } from "../../helpers/api_helper";
import { fetchMentorSubmittedSurvey } from "slices/mentor/thunk";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

interface MentorSurveyProps {
  submittedSurvey?: any;
  profilePage?: boolean;
  setShowAvailabilityCalenderTabs?: any;
}

const api = new APIClient();
export const MentorSurvey = ({
  submittedSurvey = {},
  profilePage = false,
  setShowAvailabilityCalenderTabs,
}: MentorSurveyProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState<any>(null);

  const selectProperties = createSelector(
    (state: any) => state.Global,
    (userSelection) => ({
      userConfig: userSelection.userConfig,
    })
  );

  const selectMentorSurvey = createSelector(
    (state: any) => state.Mentor,
    (mentor) => ({
      submittedMentorSurvey: mentor.submittedMentorSurvey,
    })
  );

  const { submittedMentorSurvey } = useSelector(selectMentorSurvey);

  const processResponse = useCallback(
    (response: any, commonProfileFields: any = null) => {
      if (response && response.form && response.form.pages) {
        response.form.pages.forEach((page: any) => {
          if (page.elements) {
            page.elements = page.elements.map((element: any) => {
              let entry;
              if (!isEmpty(submittedSurvey)) {
                entry = submittedSurvey[element.name];
              } else if (commonProfileFields !== null) {
                entry = commonProfileFields?.[element.name];
              }
              if (entry !== undefined && entry.length > 0) {
                return { ...element, defaultValue: entry };
              }
              return element;
            });
          }
        });
      }
      return response;
    },
    [submittedSurvey]
  );

  const getMentorForm = useCallback(async () => {
    const response = await api.get(
      "/api/v1/onboarding/profile/forms/mentor",
      {}
    );

    const { commonProfileFields } = await api.get(
      `/api/v1/onboarding/profile/mentor/common-fields`
    );
    setForm(response);
    if (!isEmpty(submittedSurvey)) {
      setForm(processResponse(response));
    }
    if (isEmpty(submittedSurvey) && commonProfileFields !== null) {
      setForm(processResponse(response, commonProfileFields));
    }
  }, [setForm, submittedSurvey, processResponse]);

  useEffect(() => {
    getMentorForm();
    // eslint-disable-next-line
  }, [submittedMentorSurvey]);

  const { userConfig } = useSelector(selectProperties);

  const startSurvey = (surveyModel: any) => {
    surveyModel.onCompleting.add((survey: any, options: any) => {
      const lastPageAnsweredQues = survey.currentPage.getValue();
      if (Object.keys(lastPageAnsweredQues).length < 2) {
        options.allowComplete = false;
        toast.error("Please select at least 2 skills.");
      }
    });
    surveyModel.onComplete.add(async (survey: any) => {
      survey.completedHtml = "<h4>Your Input have been saved!</h4>";
      await dispatch(
        postFormData(survey.data, form.formId, "mentor", profilePage)
      );
      await dispatch(fetchMentorSubmittedSurvey());
      if (setShowAvailabilityCalenderTabs)
        setShowAvailabilityCalenderTabs(true);
      if (!profilePage) {
        if (userConfig?.onboarding?.userSelections?.mentee) {
          navigate("/onboarding/create/mentee-form");
        } else {
          await dispatch(postOnboardingCompleted());
          window.location.href = "/dashboard";
        }
      }
    });

    return surveyModel;
  };

  if (isLoading || !form) {
    return <Spinners setLoading={setIsLoading} />;
  }

  const survey = new Model(form.form);
  return <Survey model={startSurvey(survey)} />;
  
};
