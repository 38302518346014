import { createSlice } from "@reduxjs/toolkit";
import {
  getUsers,
  addNewUser,
  fetchUsers,
  getInvitedUsers,
  onAcceptUserRequest,
  onStatusChange,
  uploadCsv,
  downloadCsv,
  getOrgSessions,
  deleteInvitedUser
} from "./thunk";

export const initialState = {
  users: [],
  error: {},
  loading: true,
  invitedUsers: [],
  total: 0,
  perPage: 10,
  page: 1,
  totalPages: 0,
  orgSessions: {
    sessions: [],
    total: 0,
    perPage: 10,
    page: 1,
    totalPages: 0,
  },
};

const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
      state.users = action.payload;
      state.loading = true;
    });

    builder.addCase(getUsers.rejected, (state: any, action: any) => {
      state.error = action.payload ? action.payload?.error : null;
    });

    // Fetch and store users based on type
    builder.addCase(fetchUsers.fulfilled, (state: any, action: any) => {
      state.users = action.payload;
      state.loading = true;
    });

    builder.addCase(fetchUsers.rejected, (state: any, action: any) => {
      state.error = action.payload ? action.payload?.error : null;
    });
    // End here

    builder.addCase(addNewUser.fulfilled, (state: any, action: any) => {
      // state.users.unshift(action.payload);
    });
    builder.addCase(addNewUser.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getInvitedUsers.pending, (state: any, action: any) => {
      state.loading = true;
    });

    builder.addCase(getInvitedUsers.fulfilled, (state: any, action: any) => {
      state.invitedUsers = action.payload;
    });

    builder.addCase(getInvitedUsers.rejected, (state: any, action: any) => {
      state.error = action.payload ? action.payload?.error : null;
    });

    builder.addCase(deleteInvitedUser.pending, (state: any, action: any) => {
      state.loading = true;
    });

    builder.addCase(deleteInvitedUser.fulfilled, (state: any, action: any) => {
      state.loading = false;
    });

    builder.addCase(deleteInvitedUser.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(
      onAcceptUserRequest.fulfilled,
      (state: any, action: any) => {
        state.loading = false;
      }
    );
    builder.addCase(onAcceptUserRequest.rejected, (state: any, action: any) => {
      state.error = action.error || null;
    });

    builder.addCase(onStatusChange.fulfilled, (state: any, action: any) => {
      state.loading = false;
    });
    builder.addCase(onStatusChange.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(uploadCsv.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(uploadCsv.fulfilled, (state: any, action: any) => {
      state.users = action.payload;
    });

    builder.addCase(uploadCsv.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(downloadCsv.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(downloadCsv.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(downloadCsv.rejected, (state: any, action: any) => {
      state.error = action.payload.error || null;
    });

    builder.addCase(getOrgSessions.fulfilled, (state, action) => {
      state.loading = false;
      state.orgSessions = action.payload;
    });

    builder.addCase(getOrgSessions.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getOrgSessions.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default AdminSlice.reducer;
