import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addMessage,
  deleteMessage,
  getChats,
  getContacts,
  getMessages,
} from "./thunk";
import { Message, RecentChat, UserData } from "pages/Chat/type";
interface InitialState {
  chats: RecentChat[];
  contacts: UserData[];
  messages: Message[];
  error: object;
  loading: boolean;
  currentRoomId?: string;
  chatBoxUsername?: string;
  chatBoxUserOnline?: boolean;
  unreadMessages: boolean;
  isMentor: boolean
}

interface Error {
  error: object;
}

export const initialState: InitialState = {
  chats: [],
  contacts: [],
  messages: [],
  error: {},
  loading: true,
  currentRoomId: undefined,
  chatBoxUsername: undefined,
  chatBoxUserOnline: undefined,
  unreadMessages: false,
  isMentor: false
};

const ChatsSlice = createSlice({
  name: "ChatsSlice",
  initialState,
  reducers: {
    setCurrentChatBoxUserAction(state: any, action: any) {
      const { name, roomId, online, isMentor } = action.payload;
      state.currentRoomId = roomId;
      state.chatBoxUsername = name;
      state.chatBoxUserOnline = online;
      state.isMentor = isMentor
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getChats.fulfilled,
      (state: InitialState, action: PayloadAction<RecentChat[]>) => {
        state.chats = action.payload;
        state.loading = true;
        state.unreadMessages =
         action.payload.some(
          (chat) => chat.lastMessage && !chat.lastMessage.isRead
        )
      }
    );
    builder.addCase(
      getChats.rejected,
      (state: InitialState, action: PayloadAction<Error | any>) => {
        state.error = action.payload ? action.payload?.error : null;
      }
    );
    builder.addCase(
      getContacts.fulfilled,
      (state: InitialState, action: PayloadAction<UserData[]>) => {
        state.contacts = action.payload;
        state.loading = true;
      }
    );
    builder.addCase(
      getContacts.rejected,
      (state: InitialState, action: PayloadAction<Error | any>) => {
        state.contacts = [];
      }
    );
    builder.addCase(
      getMessages.fulfilled,
      (state: InitialState, action: PayloadAction<Message[]>) => {
        state.messages = action.payload;
        state.loading = true;
      }
    );
    builder.addCase(
      getMessages.rejected,
      (state: InitialState, action: PayloadAction<Error | any>) => {
        state.messages = action.payload ? action.payload?.error : null;
      }
    );
    builder.addCase(
      addMessage.fulfilled,
      (state: InitialState, action: PayloadAction<Message>) => {
        state.messages.push(action.payload);
      }
    );
    builder.addCase(
      addMessage.rejected,
      (state: InitialState, action: PayloadAction<Error | any>) => {}
    );
    builder.addCase(
      deleteMessage.fulfilled,
      (state: InitialState, action: PayloadAction<string>) => {
        state.messages = (state.messages || []).filter(
          (message: any) => message.id !== action.payload
        );
      }
    );
    builder.addCase(
      deleteMessage.rejected,
      (state: InitialState, action: PayloadAction<Error | any>) => {}
    );
  },
});

export const { setCurrentChatBoxUserAction } = ChatsSlice.actions;

export default ChatsSlice.reducer;
