import { Card, CardBody, Container, Row, Col } from "reactstrap";
export const MenteeGuidelines = () => {
  return (
    <div className="page-content">
      <Container>
        <Row>
          <Col sm="6">
            <Card>
              <CardBody>
                <h2>Mentee Do's</h2>
                <ul className="list-unstyled">
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Take an active role in shaping the mentoring relationship.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Clearly define and communicate your goals and expectations to your mentor.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Dedicate time to the mentoring process and attend all scheduled meetings with your mentor, displaying professionalism at all times.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Prepare for meetings with planned topics and questions.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Respond promptly to your mentor's feedback, aiming to reply within two days of receiving their messages.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Be open and honest about your challenges and areas where you need support.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Maintain confidentiality. If any concerns about the mentor arise, discuss them within the mentoring relationship first.
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              <CardBody>
                <h2>Mentee Don'ts</h2>
                <ul className="list-unstyled">
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Avoid asking for advice on everything. Have a clear purpose for each request.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Do not blame your mentor if their advice doesn’t yield the desired results.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Do not expect your mentor to have all the answers. Mentorship is a collaborative process.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Don’t overcommit to obligations you cannot fulfill.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Refrain from canceling meetings with your mentor at the last minute. Respect their time and efforts.
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
