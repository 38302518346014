import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  allMentorList: [],
  AssignedMentorsList: [],
  MatchedMentorsList: [],
  submittedMentorSurvey: [],
};

const MentorSlice = createSlice({
  name: "Mentor",
  initialState,
  reducers: {
    setAllMentors(state, action) {
      state.allMentorList = action.payload;
    },
    setMentorSubmittedSurvey(state, action) {
      state.submittedMentorSurvey = action.payload;
    }
  },
});

export const {
  setAllMentors,
  setMentorSubmittedSurvey,
} = MentorSlice.actions

export default MentorSlice.reducer;
