import { APIClient } from "../../../helpers/api_helper";
import { userResetPasswordError, userResetPasswordSuccess, resetResetPassFlag } from "./reducer";

const api = new APIClient();

export const resetPassword = (password: any, token: string, history: any) => async (dispatch: any) => {
  try {
    const data = await api.create('api/v1/auth/password/reset', {
      password: password,
      token: token
    });

    if (token == null) {
      dispatch(userResetPasswordError('Token is missing'));
    } else if (data && data['status']) {
      dispatch(userResetPasswordSuccess('Password changed successfully. Please login with new password.'));
      history('/login');
    }
  } catch (error) {
    dispatch(userResetPasswordError(error));
  }
}

export const resetResetPasswordMsg = () => {
  try {
      const response = resetResetPassFlag();
      return response;
  } catch (error) {
      return error;
  }
};
