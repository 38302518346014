import { userForgetPasswordSuccess, userForgetPasswordError, resetForgotFlag } from "./reducer"
import { APIClient } from "../../../helpers/api_helper";

const api = new APIClient();

export const userForgetPassword = (values: any, history: any) => async (dispatch: any) => {
  try {
      const data: any = await api.create("/api/v1/auth/password/forgot", {
        email: values.email,
      });
      
      if (data) {
          dispatch(userForgetPasswordSuccess(
              data.message
          ))
          history("/login");
      }
  } catch (forgetError) {
      dispatch(userForgetPasswordError(forgetError))
  }
}

export const resetForgotMsg = () => {
  try {
      const response = resetForgotFlag();
      return response;
  } catch (error) {
      return error;
  }
};
