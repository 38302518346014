import React from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import AvailableSlots from "Components/AvailableSlots";
interface props {
  showModal: boolean;
  toggleRescheduleModal: any;
  selectedSession: string;
  selectedUserId: any;
  isMentor: any;
  rescheduleType: any;
}
const RescheduleSessionModal = ({
  showModal,
  toggleRescheduleModal,
  selectedSession,
  selectedUserId,
  rescheduleType,
  isMentor
}: props) => {
  return (
    <Modal isOpen={showModal} toggle={toggleRescheduleModal} size="lg" >
      <ModalHeader toggle={toggleRescheduleModal}>
        Session Details
      </ModalHeader>
      <ModalBody className=" text-center">
        <div>
          <p className="card-text">
            <AvailableSlots
              mentorId={selectedUserId}
              reschedule
              requestId={selectedSession}
              rescheduleType={rescheduleType}
              isMentor={isMentor}
              toggleRescheduleModal={toggleRescheduleModal}
            />
          </p>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default RescheduleSessionModal;
