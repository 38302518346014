import React, { useState } from "react"

import { CardBody, Collapse } from "reactstrap"
import { Link } from "react-router-dom"

const Accordion = ({ question, answer }: { question: string, answer: string }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggle = () => {
      setIsOpen(!isOpen);
    };

  return (
    <React.Fragment>
      <div>
        <div id="gen-ques-accordion" className="accordion custom-accordion">
          <div className="mb-3">
            <Link
              to="#"
              className="accordion-list"
              onClick={toggle}
              style={{ cursor: "pointer" }}
            >
              <div>{question}</div>
              <i
                className={
                  isOpen
                    ? "mdi mdi-minus accor-plus-icon"
                    : "mdi mdi-plus accor-plus-icon"
                }
              />
            </Link>

            <Collapse isOpen={isOpen}>
              <CardBody>
                <p className="mb-0">{answer}</p>
              </CardBody>
            </Collapse>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Accordion
