import React from "react";
import LineChart from "Components/charts/ApexLineChart";
import { Card, CardBody } from "reactstrap";

import { useAppSelector } from "slices";

const SessionByMonth: React.FC = () => {
  const { ratingsByMonth } = useAppSelector((state) => state.Analytics);

  const series = [
    ratingsByMonth?.["COMPLETED"] && {
      name: "Mentorship Session Completed",
      data: Object.values(ratingsByMonth?.["COMPLETED"] as any) as number[],
    },
    ratingsByMonth?.["CANCELED"] && {
      name: "Mentorship Session Cancelled",
      data: Object.values(ratingsByMonth?.["CANCELED"] as any) as number[],
    },
  ].filter(Boolean);

  return (
    <Card
      className="mx-3"
      style={{
        width: "97%",
      }}
    >
      <CardBody>
        <div id="revenue-chart">
          <LineChart
            series={series}
            categories={
              Object.keys(
                ratingsByMonth?.["COMPLETED"] || ([] as any)
              ) as string[]
            }
            titleText={"Mentorship Session Statistics"}
            yAxisLabel={"Session Count"}
            dataColors='["--bs-success","--bs-primary"]'
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default SessionByMonth;
