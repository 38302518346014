import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import withRouter from "Components/Common/withRouter";
import Breadcrumbs from "Components/Common/Breadcrumb";
import Spinners from "Components/Common/Spinner";
import SuggestedPairsTable from "../../Components/SuggestedPairsTable";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import DeleteModal from "../../Components/Common/DeleteModal";
import Paginations from "Components/Common/Pagination";

const api = new APIClient();

const ManageMembers = () => {
  document.title = "Existing Mentor-Mentee Pairs | Mentorgain";

  const [isLoading, setLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<SuggestionsResponse>({
    suggestions: [],
    total: 0,
    totalPages: 0,
  });

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [suggestionIdToDelete, setSuggestionIdToDelete] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const PERPAGE = 10;

  interface Suggestion {
    mentorFullName: string;
    menteeFullName: string;
    createdAt: string;
    id: string;
  }

  interface SuggestionsResponse {
    suggestions: Suggestion[];
    total: number;
    totalPages: number;
  }
  const getSuggestions = useCallback(async (paginationData: any = { page: currentPage, perPage: PERPAGE }) => {
    try {
      const response = await api.get("/api/v1/users/suggested-mentor-mentee-pairs", paginationData);
      const data: SuggestionsResponse = response;
      const { suggestions: suggestionsArray, total, totalPages } = data;

      const preparedSuggestions = suggestionsArray.map((suggestion: any) => ({
        mentorFullName: `${suggestion.mentor.firstName} ${suggestion.mentor.lastName} (${suggestion.mentor.email})`,
        menteeFullName: `${suggestion.mentee.firstName} ${suggestion.mentee.lastName} (${suggestion.mentee.email})`,
        createdAt: suggestion.createdAt,
        id: suggestion.id,
      }));

      setSuggestions({
        suggestions: preparedSuggestions,
        total,
        totalPages,
      });
    } catch (error: any) {
      toast.error(error.message || "Error fetching suggestions. Please try again.");
    }
  }, [currentPage]);

  const handleDeleteSuggestion = useCallback((id: any) => {
    api.delete(`/api/v1/users/suggested-mentor-mentee-pairs/${id}`, {})
      .then(() => {
        toast.success("Suggestion deleted successfully");
        getSuggestions();
        setDeleteModal(false);
        setSuggestionIdToDelete("");
      })
      .catch((error) => {
        toast.error(error.message || "Error deleting suggestion. Please try again.");
      });
  }, [getSuggestions]);

  const columns = useMemo(() => [
    {
      header: "Mentor",
      accessorKey: "mentorFullName"
    },
    {
      header: "Mentee",
      accessorKey: "menteeFullName",
    },
    {
      header: "Suggested on",
      accessorKey: "createdAt",
      cell: (cellProps: any) => (
        <div className="d-flex gap-3">
          {dayjs(cellProps.row.original.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('DD-MMM-YYYY')}
        </div>
      )
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: (cellProps: any) => (
        <div className="d-flex gap-3">
          <i
            className="mdi mdi-trash-can font-size-20 text-danger"
            id="deleteTooltip"
            onClick={() => {
              setSuggestionIdToDelete(cellProps.row.original.id);
              setDeleteModal(true);
            }}
          />
          <UncontrolledTooltip placement="top" target="deleteTooltip">
            Delete
          </UncontrolledTooltip>
        </div>
      ),
    }
  ], []);

  useEffect(() => {
    getSuggestions();
  }, [getSuggestions]);

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => handleDeleteSuggestion(suggestionIdToDelete)}
          onCloseClick={() => {
            setDeleteModal(false);
            setSuggestionIdToDelete("");
          }}
          message="Are you sure you want to delete this pairing?"
        />
        <Container fluid>
          <Breadcrumbs title="Pairing" breadcrumbItem="Exisiting Pairs" />
          <Row>
            <Col lg="12">
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <Card>
                  <CardBody>
                    <SuggestedPairsTable
                      columns={columns}
                      data={suggestions.suggestions || []}
                      tableClass="table-sm align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      currentPage={currentPage}
                      totalPages={suggestions.totalPages}
                      setCurrentPage={setCurrentPage}
                    />
                    <Paginations
                      perPageData={PERPAGE}
                      totalDataCount={suggestions.total}
                      totalPages={suggestions.totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={true}
                      paginationClass="pagination pagination-rounded"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ManageMembers);
