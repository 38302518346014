import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError, getPublicConfig } from "../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

// import images
import logoImg from "../../assets/images/logo.png";
import { createSelector } from "reselect";
import GoogleIcon from "../../assets/images/google.svg";
import MicrosoftIcon from "../../assets/images/microsoft.svg";
import mentorshipBg from "../../assets/images/mentorship.png";
import { isEmpty } from "lodash";

const Register = (props) => {
  //meta title
  document.title = "Register | Mentorgain";
  const [show, setShow] = useState(false);

  const dispatch = useDispatch<any>();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      recaptcha: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter your first name"),
      lastName: Yup.string().required("Please enter your last name"),
      email: Yup.string().required("Please enter your email"),
      password: Yup.string().required("Please enter your Password"),
    }),
    onSubmit: async (values) => {
      let user = values
      if (!process.env.REACT_APP_DISABLE_RECAPTCHA) {
        // @ts-ignore
        const token = await recaptchaRef.current.executeAsync();
        user = { ...values, recaptcha: token }
      }
      dispatch(registerUser(user));
    },
  });

  const selectProperties = createSelector(
    (state: any) => state.Account,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      loading: account.loading,
      success: account.success,
    })
  );

  const selectPublicConfigProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      publicConfig: global.publicConfig,
    })
  );

  const { publicConfig } = useSelector(selectPublicConfigProperties);
  const { user, registrationError, success } = useSelector(selectProperties);

  useEffect(() => {
    if (success) {
      validation.resetForm();
    }

    if (isEmpty(publicConfig)) {
      dispatch(getPublicConfig());
    }
    dispatch(apiError());
  }, [dispatch, success, validation, publicConfig]);

  const redirectToGoogle = () => {
    const subdomain = window.location.host.split(".")[0];
    window.location.assign(`${process.env.REACT_APP_AUTH_URL}/google/init/${subdomain}`);
  };

  const redirectToMicrosoft = () => {
    window.location.assign(`${process.env.REACT_APP_AUTH_URL}/microsoft/init`);
  };

  const recaptchaRef = useRef();

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Sign up for your {publicConfig?.name} Mentoring account</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={mentorshipBg} alt="" className="img-fluid pt-4" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt="Mentorgain Logo"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  {publicConfig?.enabledProviders && (
                    <>
                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign up with</h5>

                        <div className="p-2">
                          {publicConfig?.enabledProviders?.includes(
                            "google"
                          ) && (
                              <div className="d-grid">
                                <Button
                                  className="btn btn-soft-primary btn-block"
                                  onClick={redirectToGoogle}
                                >
                                  <img
                                    src={GoogleIcon}
                                    alt="Continue with Google"
                                    className="mx-2"
                                    style={{ width: "16px" }}
                                  />
                                  Continue With Google
                                </Button>
                              </div>
                            )}

                          {publicConfig?.enabledProviders?.includes(
                            "microsoft"
                          ) && (
                              <div className="mt-3 d-grid">
                                <Button
                                  className="btn btn-soft-primary btn-block"
                                  onClick={redirectToMicrosoft}
                                >
                                  <img
                                    src={MicrosoftIcon}
                                    alt="Continue with Microsoft"
                                    className="mx-2"
                                    style={{ width: "16px" }}
                                  />
                                  Continue With Microsoft
                                </Button>
                              </div>
                            )}
                        </div>
                      </div>
                      {publicConfig?.enabledProviders?.length > 1 && publicConfig?.enabledProviders.includes("email-password") && (
                        <div className="p-2">
                          <div className="separator">OR</div>
                        </div>
                      )}
                    </>
                  )}
                  {publicConfig?.enabledProviders && publicConfig?.enabledProviders.includes("email-password") && (
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {user && user ? (
                          <Alert color="success">
                            User registered. Please check your inbox for a confirmation email and follow the instructions.
                          </Alert>
                        ) : null}

                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError.message}</Alert>
                        ) : null}

                        <div className="row mb-3">
                          <div className="col">
                            <Label className="form-label">First Name</Label>
                            <Input
                              id="firstName"
                              name="firstName"
                              className="form-control"
                              placeholder="Enter first name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstName || ""}
                              invalid={
                                !!(
                                  validation.touched.firstName &&
                                  validation.errors.firstName
                                )
                              }
                            />
                            {validation.touched.firstName &&
                              validation.errors.firstName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.firstName}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="col">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              id="lastName"
                              name="lastName"
                              className="form-control"
                              placeholder="Enter last name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastName || ""}
                              invalid={
                                !!(
                                  validation.touched.lastName &&
                                  validation.errors.lastName
                                )
                              }
                            />
                            {validation.touched.lastName &&
                              validation.errors.lastName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.lastName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              !!(
                                validation.touched.email &&
                                validation.errors.email
                              )
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              type={show ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                !!(
                                  validation.touched.password &&
                                  validation.errors.password
                                )
                              }
                            />
                            <button
                              onClick={() => setShow(!show)}
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                            {validation.touched.password &&
                              validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        {
                          !process.env.REACT_APP_DISABLE_RECAPTCHA &&
                          <>
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              size="invisible"
                              sitekey="6LdJpx4qAAAAAKUdOHN2a9xBYGGjZuqwo6dWdr8P"
                            />

                            <div className="small">
                              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a> apply.
                            </div>
                          </>
                        }
                        <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block "
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">By registering you agree to the Mentorgain</p>
                          <p className="mb-0">
                            <Link to="https://mentorgain.com/terms-conditions/" className="text-primary">
                              Terms and Conditions
                            </Link>{" "}
                            and{" "}
                            <Link to="https://mentorgain.com/privacy-policy/" className="text-primary">
                              Privacy Policy
                            </Link>
                          </p>
                        </div>

                      </Form>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Mentorgain.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
