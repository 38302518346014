import { createSlice } from "@reduxjs/toolkit";
import { fetchProfileAnalytics } from "./thunk";

interface ProfileState {
  userProfile: any;
  userAnalytics: Record<string, any[]>;
}

const initialState: ProfileState = {
  userProfile: null,
  userAnalytics: {},
};

const profileSlice = createSlice({
  name: "UserProfile",
  initialState,
  reducers: {
    fetchProfileSuccess(state, action: any) {
      state.userProfile = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchProfileAnalytics.fulfilled, (state, action) => {
      state.userAnalytics = action.payload;
    });
    builder.addCase(fetchProfileAnalytics.pending, (state, action) => {
      state.userAnalytics = {};
    });
    builder.addCase(fetchProfileAnalytics.rejected, (state, action) => {
      state.userAnalytics = {};
    });
  },
});

export const { fetchProfileSuccess } = profileSlice.actions;

export default profileSlice.reducer;
