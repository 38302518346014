import React, { useEffect } from "react";
import withRouter from "Components/Common/withRouter";
import { useNavigate } from "react-router-dom";

import { logoutUser } from "../../slices/thunk";

//redux
import { useDispatch } from "react-redux";

const Logout = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutUser(navigate));
  }, [dispatch, navigate]);

  return <></>;
};

export default withRouter(Logout);
