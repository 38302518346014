import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { createSelector } from "reselect";
import { disconnectCalendar, getCalendarStatus } from "slices/onboarding/thunk";
import GoogleCalendarIcon from "../../assets/images/google-calendar.svg";
import OutlookIcon from "../../assets/images/microsoft-outlook.svg";
import { isEmpty } from "lodash";
import { getPublicConfig } from "slices/thunk";

const Calendar = () => {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getCalendarStatus());
  }, [dispatch]);

  const selectCalendarProperties = createSelector(
    (state: any) => state.Onboarding,
    (onboarding) => ({
      calendarStatus: onboarding.calendarStatus,
    })
  );

  const selectProfileProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
      publicConfig: global.publicConfig,
    })
  );

  const { user, publicConfig } = useSelector(selectProfileProperties);
  const { calendarStatus } = useSelector(selectCalendarProperties);

  const redirectToGoogle = () => {
    const subdomain = window.location.host.split(".")[0];
    window.open(`${process.env.REACT_APP_AUTH_URL}/google/calendar/${subdomain}`, 'Google Calendar', 'width=560,height=340,toolbar=0,menubar=0,location=0');
  }
  const redirectToMicrosoft = () => {
    window.open(`${process.env.REACT_APP_AUTH_URL}/microsoft/calendar`, 'Microsoft Calendar', 'width=560,height=340,toolbar=0,menubar=0,location=0');
  }

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === process.env.REACT_APP_AUTH_URL) {
        dispatch(getCalendarStatus());
      }
    };
    window.addEventListener("message", handleMessage);

    if (isEmpty(publicConfig)) {
      dispatch(getPublicConfig());
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [dispatch, publicConfig]);

  const handleCalendarDisconnect = () => {
    dispatch(disconnectCalendar(calendarStatus?.provider));
  };

  return (
    <>
      <div className="text-center mb-3 mt-5">
        <h4>Link Calendar</h4>
      </div>
      {calendarStatus && calendarStatus?.status ? (
        <div className="text-center mb-3 d-flex justify-content-center">
          <div
            style={{
              padding: "3rem",
              borderRadius: "10px",
              border: "solid 1px #e3e6ea",
            }}
          >
            <i
              className="mdi mdi-calendar-check text-success"
              style={{ fontSize: "3rem" }}
            />
            <p>{user.email}</p>
            <div>
              <Button onClick={handleCalendarDisconnect}>Disconnect</Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          {publicConfig?.enabledProviders?.includes("google") && (
            <Button
              className="btn btn-soft-primary btn-block me-2"
              onClick={redirectToGoogle}
            >
              <img
                src={GoogleCalendarIcon}
                alt="Continue with Google"
                className="mx-2"
                style={{ width: "16px" }}
              />
              Connect Google Calendar
            </Button>
          )}

          {publicConfig?.enabledProviders?.includes("microsoft") && (
            <Button
              className="btn btn-soft-primary btn-block"
              onClick={redirectToMicrosoft}
            >
              <img
                src={OutlookIcon}
                alt="Continue with Google"
                className="mx-2"
                style={{ width: "16px" }}
              />
              Connect Microsoft Calendar
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default Calendar;
