import React from "react";
import { Link } from "react-router-dom";
import SidebarContent from "./SidebarContent";

import mentorgainlogoBlack from "../../assets/images/mentorgain-black.png";
import mentorgainlogoWhite from "../../assets/images/mentorgain-white.png";
import logoBigDark from "../../assets/images/logo-big-dark.png";
import logoBigLight from "../../assets/images/logo-big-light.png";

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={mentorgainlogoBlack} alt="" height="25" />
            </span>
            <span className="logo-lg">
              <img src={logoBigDark} alt="" height="50" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={mentorgainlogoWhite} alt="" height="25" />
            </span>
            <span className="logo-lg">
              <img src={logoBigLight} alt="" height="50" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent   />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;