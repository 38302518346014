import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Label, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
  resetChangePasswordMsg,
  userChangePassword,
} from "slices/auth/change_password/thunk";
import { toast } from "react-toastify";

const ChangePassword = (props: any) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const dispatch: any = useDispatch();
  
  const selectProperties = createSelector(
    (state: any) => state.ChangePassword,
    (changePassword) => ({
      errorMsg: changePassword.changePasswordError,
      successMsg: changePassword.changePasswordSuccessMsg,
    })
  );

  const { errorMsg, successMsg } = useSelector(selectProperties);

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      currentPassword: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Please enter current password"),
      newPassword: Yup.string().required("Please enter new password"),
    }),
    onSubmit: (values: any) => {
      dispatch(userChangePassword(values));
    },
  });

  useEffect(() => {
    if (errorMsg || successMsg) {
      if (errorMsg) {
        Array.isArray(errorMsg)
          ? toast.error(errorMsg[0])
          : toast.error(errorMsg);
      }
      if (successMsg) {
        toast.success(successMsg);
        validation.values.currentPassword = "";
        validation.values.newPassword = "";
      }
      dispatch(resetChangePasswordMsg());
    }
  }, [dispatch, errorMsg, validation, successMsg]);

  return (
    <React.Fragment>
      <div>
        <Row className="justify-content-center">
          <Col md={4}>
            <h4 className="text-center mb-4 mt-3">Change Current Password</h4>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Current Password</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="currentPassword"
                      value={validation.values.currentPassword || ""}
                      type={showCurrentPassword ? "text" : "password"}
                      placeholder="Enter current password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(
                          validation.touched.currentPassword &&
                          validation.errors.currentPassword
                        )
                      }
                    />
                    <button
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      className="btn btn-light "
                      type="button"
                      id="password-addon"
                    >
                      <i className="mdi mdi-eye-outline"></i>
                    </button>
                  </div>
                  {validation.touched.currentPassword &&
                  validation.errors.currentPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.currentPassword}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">New Password</Label>
                  <div className="input-group auth-pass-inputgroup">
                    <Input
                      name="newPassword"
                      value={validation.values.newPassword || ""}
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(
                          validation.touched.newPassword &&
                          validation.errors.newPassword
                        )
                      }
                    />
                    <button
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      className="btn btn-light "
                      type="button"
                      id="password-addon"
                    >
                      <i className="mdi mdi-eye-outline"></i>
                    </button>
                  </div>
                  {validation.touched.newPassword &&
                  validation.errors.newPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.newPassword}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mt-3 d-grid">
                  <button className="btn btn-primary btn-block " type="submit">
                    Change Password
                  </button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
