import {useEffect, useState} from "react";
import {APIClient} from "../../helpers/api_helper";
import Spinners from "../../Components/Common/Spinner";
import {Model} from "survey-core";
import {Survey} from "survey-react-ui";
import withRouter from "../../Components/Common/withRouter";
import {toast} from "react-toastify";

const api = new APIClient();

const PostSessionFeedback = (props) => {
  const {sessionId} = props.router.params;
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState<any>(null);
  const [formId, setFormId] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    api.get(`/api/v1/sessions/${sessionId}/feedback-form`).then((response: any) => {
      setForm(response.form);
      setFormId(response.formId)
      setIsLoading(false);
    }).catch((error) => {
      toast.error(error.message || "Error fetching feedback form. Please try again.")
    });
  }, [sessionId])

  const submitFeedback = (formResponse) => {
    api.create(`/api/v1/sessions/${sessionId}/feedbacks`, {
      formId,
      feedback: formResponse
    }).then((response: any) => {
      toast.success("Thank you for submitting your feedback!")
    }).catch((error) => {
      toast.error(error.message || "Error submitting feedback form. Please try again.")
    });
  }

  if (isLoading || !form) {
    return <Spinners setLoading={setIsLoading}/>
  }

  const survey = new Model(form);

  survey.onComplete.add((survey: any) => {
    survey.completedHtml = "<h1>Thank You</h1>";
    submitFeedback(survey.data);
  });

  return (
    <>
      <div className="page-content">
        <Survey model={survey}/>
      </div>
    </>
  );
};

export default withRouter(PostSessionFeedback);
