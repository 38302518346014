import { Card, CardBody, Col, Row } from "reactstrap";

//Import Images
import profile1 from "assets/images/profile-img.png";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { capitalize } from "lodash";

const WelcomeCard = () => {
  const selectProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
    })
  );

  const { user } = useSelector(selectProperties);

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary-subtle">
        <Row>
          <Col xs={7}>
            <div className="text-primary p-3">
              <h5 className="text-primary">Welcome Back !</h5>
            </div>
          </Col>
          <Col xs={5} className="align-self-end">
            <img src={profile1} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col xs={12}>
            <div className="avatar-md profile-user-wid mb-0">
              <img
                src={user.avatarURL || profile1}
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="d-flex flex-column">
          <p></p>
            <h5 className="font-size-15">
              Hello, {user.firstName}
            </h5>
          </Col>
          <Col xs={6} md={4} className="d-flex-end flex-column">
          <p className="text-muted mb-0">Title</p>
            <h5 className="font-size-15">{capitalize(user?.title)}</h5>
          </Col>
          <Col xs={6} md={4} className="d-flex-end flex-column">
          <p className="text-muted mb-0">Department</p>
            <h5 className="font-size-15">
              {capitalize(user?.department)}
            </h5>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default WelcomeCard;