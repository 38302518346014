import { loginSuccess, apiError, logoutUserSuccess, resetLoginFlag } from "./reducer";
import { APIClient } from "../../../helpers/api_helper";
import { setLoggedInUser } from "../../global/thunk";
import { setLogoutAction } from "slices/global/reducer";

const api = new APIClient();

export const loginUser = (user: any, navigate: any) => async (dispatch: any) => {
  try {
      const data: any = await api.create("/api/v1/auth/login", user);
      dispatch(loginSuccess(data));
      dispatch(setLoggedInUser(data));
      navigate('/dashboard');
  } catch (error: any) {
    const { status } = error;
    switch (status) {
      case 'PENDING_APPROVAL':
        navigate('/error/pending-approval');
        break;
      case 'DEACTIVE':
        navigate('/error/user-deactive');
        break;
      case 'BLOCKED':
        navigate('/error/user-blocked');
        break;
      case 'REJECTED':
        navigate('/error/user-rejected');
        break;
      default:
        break;
    }
    dispatch(apiError(error.message));
  }
}

export const logoutUser = (navigate: any) => async (dispatch: any) => {
    try {
        const data: any = await api.create("/api/v1/auth/logout", {});
        dispatch(setLogoutAction());
        dispatch(logoutUserSuccess(data));
        navigate('/login');
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const resetLoginMsgFlag = () => {
    try {
        const response = resetLoginFlag();
        return response;
    } catch (error) {
        return error;
    }
};
