import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import mentor from "../../assets/images/mentor.svg";
import mentee from "../../assets/images/mentee.svg";
import "../../assets/scss/custom/components/MentorMenteeSurvey.scss";
import { postOnboardingCompleted, userTypeSelections } from "slices/onboarding/thunk";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import Pages404 from "pages/Utility/pages-404";

const MentorMenteeSurvey = () => {
  const [userSelection, setUserSelection] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      userConfig: global.userConfig,
      permissions: global.permissions,
    })
  );
  const { userConfig, permissions } = useSelector(selectProperties);
  const isOrgAdmin =
    permissions.findIndex(
      (permission) => permission?.resource === "org-admin"
    ) !== -1;

  const handleSurvey = () => {
    dispatch(userTypeSelections(userSelection, userConfig['onboarding']));
    if (userSelection.includes("mentor")) {
      navigate("/onboarding/set-availability");
    } else if (userSelection.includes("mentee")) {
      navigate("/onboarding/create/mentee-form");
    }
  }

  const handleBack = () => {
    navigate("/onboarding/upload-profile");
  };

  const handleSkip = async () => {
    await dispatch(postOnboardingCompleted());
    navigate("/dashboard");
  };

  const toggleUserSelection = (type: any) => {
    if (type === "both") {
      if (userSelection.includes("mentor") && userSelection.includes("mentee")) {
        setUserSelection([]);
      } else {
        setUserSelection(["mentor", "mentee"]);
      }
    } else {
      if (userSelection.includes(type)) {
        setUserSelection(userSelection.filter((item: any) => item !== type));
      } else {
        setUserSelection([...userSelection, type]);
      }
    }
  }

  return (
    userConfig?.onboardingCompleted ? <Pages404 /> : (
      <div className="page-content">
        <Container fluid>
            <>
              <Row className="d-flex justify-content-center text-center">
                <Col>
                  <h3>Select Your Preference</h3>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center mt-5">
                <Col md={4} className="mb-3 d-flex justify-content-center flex-column align-items-center">
                  <div
                    style={{
                      cursor: 'pointer',
                      width: '90%',
                      height: '90%',
                      backgroundColor: '#f8f8fb',
                      padding: '20px',
                      border: '1px solid #e3e3e3',
                      borderRadius: '5px',
                      marginBottom: '10px',
                    }}
                    className={`${userSelection.includes('mentor') ? 'selected' : ''}`}
                    onClick={() => toggleUserSelection('mentor')}
                  >
                    <img className="card-img" src={mentor} alt="Mentor" />
                  </div>
                  <h5 className="text-center">Mentor</h5>
                </Col>

                <Col md={4} className="mb-3 d-flex justify-content-center flex-column align-items-center">
                  <div
                    style={{
                      cursor: 'pointer',
                      width: '90%',
                      height: '90%',
                      backgroundColor: '#f8f8fb',
                      padding: '20px',
                      border: '1px solid #e3e3e3',
                      borderRadius: '5px',
                      marginBottom: '10px'
                    }}
                    className={`${userSelection.includes('mentee') ? 'selected' : ''}`}
                    onClick={() => toggleUserSelection('mentee')}
                  >
                    <img className="card-img" src={mentee} alt="Mentee" />
                  </div>
                  <h5 className="text-center">Mentee</h5>
                </Col>
              </Row>
              <Row>
                <label className="d-flex justify-content-center align-items-center" style={{width: '100%'}}>
                  <input
                    type="checkbox"
                    checked={userSelection.includes("mentor") && userSelection.includes("mentee")}
                    onChange={() => toggleUserSelection("both")}
                  />
                  <span className="ms-2">Select both Mentor and Mentee</span>
                </label>
              </Row>
              <Row>
                <div className="d-flex justify-content-center mt-2">
                  <div className="col-8 d-flex justify-content-between ">
                    <Button color="primary" className="save-user btn-primary px-3 text-white" onClick={handleBack}>Back</Button>
                    <Button color="primary" disabled={userSelection.length === 0} className="save-user btn-primary px-3 text-white ms-3" onClick={handleSurvey}>Next</Button>
                  </div>
                  {isOrgAdmin && (
                    <Button
                      color="primary"
                      className="save-user btn-primary px-3 text-white ms-3 justify-content-end"
                      onClick={handleSkip}
                    >
                      Skip
                    </Button>
                  )}
                </div>
              </Row>
            </>
        </Container>
      </div>
    )
  );
};

export default MentorMenteeSurvey;
