import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { fetchProfileSuccess } from "./reducer";

const api = new APIClient();

export const fetchProfile = (id: string) => async (dispatch: any) => {
  try {
    const response = await api.get(`/api/v1/users/${id}/profile`, null);
    dispatch(fetchProfileSuccess(response));
  } catch (error) {
    throw error;
  }
};

export const fetchProfileAnalytics = createAsyncThunk(
  "profile/fetchProfileAnalytics",
  async (paramsData: any) => {
    try {
      const { id, userType } = paramsData;
      const response = await api.get(`/api/v1/analytics/${id}/activity`, {
        userType: userType,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);
