import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartDynamicColor";
const ApexLineChart = ({
  dataColors,
  series,
  categories,
  titleText,
  yAxisLabel,
}: any) => {
  const apaexlineChartColors = getChartColorsArray(dataColors);

  const min = Math.min(...series.map((s: any) => Math.min(...s.data)), 0);

  const max = Math.max(...series.map((s: any) => Math.max(...s.data)), 0);

  const options: any = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: apaexlineChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    series,
    title: {
      text: titleText,
      align: "left",
      style: {
        fontWeight: "500",
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories,
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: yAxisLabel || "",
      },
      min,
      max,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
      className="apex-charts"
    />
  );
};

export default ApexLineChart;
