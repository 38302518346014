import { Card, CardBody, Container, Row, Col } from "reactstrap";

export const MentorGuidelines = () => {
  return (
    <div className="page-content">
      <Container>
        <Row>
          <Col sm="6">
            <Card>
              <CardBody>
                <h2>Mentor Do's</h2>
                <ul className="list-unstyled">
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Set aside dedicated time for mentoring and honor all scheduled appointments.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Engage in regular communication through the Mentorgain platform, including messaging and meetings.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Respond to your mentee's messages within two days.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Keep all shared information confidential. If there's a need to discuss sensitive issues, do so within the mentoring relationship first.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Establish open and honest communication, encouraging idea exchange.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Foster creativity and independence, helping your mentee build self-confidence.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Provide honest, constructive, and timely feedback.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Create a safe space for your mentee to discuss concerns and ask questions.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-check-circle text-primary me-2"></i>
                    Above all, listen actively.
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              <CardBody>
                <h2>Mentor Don'ts</h2>
                <ul className="list-unstyled">
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Do not accept a mentee who is in your direct reporting hierarchy. Instead, guide them to connect with someone outside their direct chain of command.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Do not try to offer advice on every topic; focus on your areas of expertise.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Avoid sharing personal issues or experiences unless they are directly relevant and constructive to the mentorship.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Do not neglect your mentee's needs. If you're too busy, inform your mentee in advance so they know when to expect your support.
                  </li>
                  <li className="d-flex align-items-start mb-2">
                    <i className="bx bx-x text-danger me-2"></i>
                    Do not criticize harshly. Offer support and provide constructive feedback instead.
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
