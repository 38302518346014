import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { Message } from "./type";
import EmojiPicker from "emoji-picker-react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Spinners from "Components/Common/Spinner";
import {
  addMessage as onAddMessage,
  deleteMessage as onDeleteMessage, getMessages as onGetMessages
} from "../../slices/chats/thunk"
import DeleteModal from "../../Components/Common/DeleteModal";

dayjs.extend(relativeTime);

interface Props {
  chatBoxUsername?: string;
  chatBoxUserOnline?: boolean;
  messages: Message[];
  loading: boolean;
  currentRoomId?: string;
  isMentor: boolean;
}

const UserChat: React.FC<Props> = ({
  chatBoxUsername,
  chatBoxUserOnline,
  messages,
  loading,
  currentRoomId,
  isMentor,
}) => {
  const dispatch = useDispatch<any>();
  const scrollRef = useRef<any>(null);

  const [isLoading, setLoading] = useState(loading)
  const [copyMsgAlert, setCopyMsgAlert] = useState<boolean>(false);
  const [search_Menu, setSearch_Menu] = useState<boolean>(false);
  const [other_Menu, setOther_Menu] = useState<boolean>(false);
  const [isDisable, setDisable] = useState<boolean>(false);
  const [emoji, setEmoji] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [curMessage, setCurMessage] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [messageIdToDelete, setMessageIdToDelete] = useState<string>("");

  //Toggle Chat Box Menus
  const toggleSearch = () => setSearch_Menu(!search_Menu);

  const toggleOther = () => setOther_Menu(!other_Menu);

  // scroll simple bar
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.getScrollElement().scrollTop = scrollRef.current.getScrollElement().scrollHeight;
    }
  }, [messages])

  // copy msg
  const copyMsg = (message: Message) => {
    navigator.clipboard.writeText(message.content);
    setCopyMsgAlert(true);
    setTimeout(() => {
      setCopyMsgAlert(false);
    }, 1000);
  };

  // search
  const handelSearch = () => {
    const searchInput = document.getElementById("searchMessage") as HTMLInputElement;
    const searchFilter = searchInput.value.toUpperCase();
    const searchUL = document.getElementById("users-conversation") as HTMLInputElement;
    const searchLI = searchUL.getElementsByTagName("li");

    Array.prototype.forEach.call(searchLI, (search: HTMLElement) => {
      const a = search.getElementsByTagName("p")[0] || '';
      const txtValue = a.textContent || a.innerText || '';

      if (txtValue.toUpperCase().indexOf(searchFilter) > -1) {
        search.style.display = "";
      } else {
        search.style.display = "none";
      }
    });
  };


  // emoji
  const [emojiArray, setEmojiArray] = useState<string[]>([]);
  const onEmojiClick = (event: any, emojiObject: any) => {
    setEmojiArray([...emojiArray, emojiObject.emoji]);
    setCurMessage(curMessage + event.emoji);
    setDisable(true)
  };

  const addMessage = () => {
    if (curMessage !== "" || selectedImage !== null) {
      dispatch(onAddMessage({
        message: curMessage,
        receiverUserId: currentRoomId,
        callback: setCurMessage,
      }));
      setDisable(false)
      setEmoji(false);
      setSelectedImage(null)
    }
  };

  const onKeyPress = (e: any) => {
    const { key, value } = e;
    if (key === "Enter") {
      setCurMessage(value);
      setDisable(true)
      addMessage();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(onGetMessages(currentRoomId));
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, currentRoomId]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => {
          dispatch(onDeleteMessage(messageIdToDelete)).finally(() => {
            setDeleteModal(false);
            setMessageIdToDelete("")
          })
        }}
        onCloseClick={() => {
          setDeleteModal(false);
          setMessageIdToDelete("")
        }}
        message="Are you sure you want to delete this message?"
      />
      <div className="w-100 user-chat">
        <Card>
          <div className="p-4 border-bottom ">
            <Row>
              <Col md={4} xs={9}>
                <h5 className="font-size-15 mb-1">{chatBoxUsername} </h5>
                <p className="text-muted mb-0">
                  <i
                    className={
                      chatBoxUserOnline ?
                        "mdi mdi-circle text-success align-middle me-2" :
                        "mdi mdi-circle text-warning align-middle me-1"
                    }
                  />
                  {chatBoxUserOnline ? "Active now" : "Offline"}
                </p>
              </Col>
              <Col md={8} xs={3}>
                <ul className="list-inline user-chat-nav text-end mb-0">
                  <li className="list-inline-item d-none d-sm-inline-block">
                    <Dropdown className="me-1" isOpen={search_Menu} toggle={toggleSearch}>
                      <DropdownToggle className="btn nav-btn" tag="a">
                        <i className="bx bx-search-alt-2" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-md">
                        <Form className="p-3">
                          <FormGroup className="m-0">
                            <InputGroup>
                              <Input
                                type="text" placeholder="Search ..."
                                id="searchMessage"
                                aria-label="Search user messages"
                                onChange={handelSearch}
                              />
                              <Button color="primary" type="submit">
                                <i className="mdi mdi-magnify" />
                              </Button>
                            </InputGroup>
                          </FormGroup>
                        </Form>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                  <li className="list-inline-item">
                    <Dropdown isOpen={other_Menu} toggle={toggleOther}>
                      <DropdownToggle className="btn nav-btn" tag="a">
                        <i className="bx bx-dots-horizontal-rounded" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                          href={`/profile/${currentRoomId}?${
                            isMentor ? "viewingAs=MENTOR" : "viewingAs=MENTEE"
                          }`}
                        >
                          View Profile
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div>
            <div className="chat-conversation p-3">
              <SimpleBar ref={scrollRef} style={{ height: "470px" }}>
                {isLoading ? <Spinners setLoading={setLoading} /> :
                  <ul className="list-unstyled" id="users-conversation">
                    {
                      messages && (messages || [])?.map((message: Message) => {
                        const isMyMessage = message.senderId !== currentRoomId
                        return (
                          <li
                            key={message.id}
                            className={!isMyMessage ? "" : "right"}
                          >
                            <div className="conversation-list">
                              <UncontrolledDropdown>
                                <DropdownToggle href="#!" tag="a" className="dropdown-toggle">
                                  <i className="bx bx-dots-vertical-rounded" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={(e) => copyMsg(message)}
                                    href="#"
                                  >
                                    Copy
                                  </DropdownItem>
                                  {isMyMessage && (
                                    <DropdownItem
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setMessageIdToDelete(message.id);
                                      }}
                                      href="#"
                                    >
                                      Delete
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              <div className="ctext-wrap">
                                <div>{message.content}</div>
                                <div className="chat-time mb-0">
                                  <i className="bx bx-time-five align-middle me-1"></i>
                                  {dayjs(message.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').fromNow()}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                }
              </SimpleBar>
            </div>

            {copyMsgAlert && <UncontrolledAlert color='warning' role="alert">Message copied</UncontrolledAlert>}
            {emoji && <EmojiPicker onEmojiClick={onEmojiClick} width={250} height={382} />}

            <div className="p-3 chat-input-section">
              <Row>
                <Col>
                  <div className="position-relative">
                    <Input type="text" value={curMessage} onKeyPress={onKeyPress} onChange={e => {
                      setCurMessage(e.target.value);
                      setDisable(true)
                    }} className="chat-input" placeholder="Enter Message..." />
                    <div className="chat-input-links">
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item" onClick={() => setEmoji(!emoji)}>
                          <Link to="#">
                            <i className="mdi mdi-emoticon-happy-outline me-1" id="Emojitooltip" />
                            <UncontrolledTooltip placement="top" target="Emojitooltip">
                              Emojis
                            </UncontrolledTooltip>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button type="button" color="primary" disabled={!isDisable} onClick={() => addMessage()}
                    className="btn btn-primary btn-rounded chat-send w-md ">
                    <span className="d-none d-sm-inline-block me-2"> Send</span>
                    <i className="mdi mdi-send" />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default UserChat;