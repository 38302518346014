import React from 'react';
import user1 from "../assets/images/users/user_profile.svg";

const UserList = ({ users, onSelect, selectedUser, userType, removeUser }) => {
  const redirectToProfile = (user: any) => {
    window.open(`/profile/${user.id}`, '_blank');
  }
  
  return (
    <div className="mx-auto p-0 rounded col-12 col-md-6">
      {users.length > 0 ? (
        <ul className="list-unstyled">
          {users.filter(user => user.id !== removeUser?.id).map((user: any) => (
            <li
              key={user.id}
              className="d-flex align-items-center justify-content-between mb-2 p-2 border rounded bg-white"
              onClick={() => onSelect(user)}
            >
              <div className="d-flex align-items-center w-100">
                <img src={user.avatarUrl || user1} alt="Avatar" className="rounded-circle" height="40" width="40" />
                <div className="ms-2 d-flex justify-content-between w-100">
                  <div className="fw-bold" onClick={() => redirectToProfile(user)}>{`${user.firstName} ${user.lastName}`}</div>
                  <div className="text-muted me-5 text-capitalize font-size-10">{user.department}</div>
                </div>
              </div>
              <input 
                type="radio" 
                name={userType} 
                value={user.id} 
                className="me-4"
                checked={selectedUser?.id === user.id} 
                onChange={() => onSelect(user)}
              />
            </li>
          ))}
        </ul>
      ) : (
        <div className="d-flex align-items-center justify-content-between mb-2 p-2 border rounded bg-white">
          No {userType}s found
        </div>
      )}
    </div>
  );
};

export default UserList;
