import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  acceptInviteMsg: null,
  acceptInviteError: null,
};

const acceptInviteSlice = createSlice({
  name: "acceptInvite",
  initialState,
  reducers: {
      userAcceptInviteSuccess(state, action) {
        state.acceptInviteMsg = action.payload
      },
      userAcceptInviteError(state, action) {
        state.acceptInviteError = action.payload
      },
      resetAcceptInviteMsg(state) {
        state.acceptInviteMsg = null
        state.acceptInviteError = null
      }
  },
});

export const {
  userAcceptInviteSuccess,
  userAcceptInviteError,
  resetAcceptInviteMsg
} = acceptInviteSlice.actions

export default acceptInviteSlice.reducer;
