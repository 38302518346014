import React, { useEffect, useState } from "react";
import { Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { RecentChat, UserData } from "./type";
import Spinners from "Components/Common/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getChats as onGetChats,
  getContacts as onGetContacts,
} from "../../slices/chats/thunk"

dayjs.extend(relativeTime);

interface Props {
  userChatOpen: (user: UserData) => void;
  currentRoomId?: string
}
interface chatState {
  Chats: {
    chats: RecentChat[];
    contacts: UserData[];
    loading: boolean;
  }
}

const ChatList: React.FC<Props> = ({ userChatOpen, currentRoomId }) => {

  const dispatch = useDispatch<any>();
  const [activeTab, setActiveTab] = useState<string | number>("chat");

  const selectProperties = createSelector(
    [(state: chatState) => state.Chats, (state) => state.Global],
    (chats, global) => ({
      chats: chats.chats,
      contacts: chats.contacts,
      loading: chats.loading,
      user: global.user,
    })
  );

  const { chats, contacts, loading, user } = useSelector(selectProperties);
  const [isLoading, setLoading] = useState(loading)
  useEffect(() => {
    dispatch(onGetChats());
    dispatch(onGetContacts());
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(onGetChats());
      dispatch(onGetContacts());
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);

  // change tab
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //search recent user
  const searchUsers = () => {
    var input: any, filter: any, ul: any, li: any, a: any, i: any, txtValue: any;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  return (
    <React.Fragment>
      <div className="chat-leftsidebar me-lg-4">
        <div>
          <div className="py-4 border-bottom">
            <div className="d-flex">
              <div className="align-self-center me-3">
                {user.avatarUrl ?
                  <div className="align-self-center me-3">
                    <img src={user.avatarUrl} className="rounded-circle avatar-xs" alt="" />
                  </div>
                  :
                  <div className="avatar-xs align-self-center me-3">
                    <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                      {Array.from(user.firstName as string)[0]}
                    </span>
                  </div>
                }
              </div>
              <div className="flex-grow-1">
                <h5 className="font-size-15 mt-0 mb-1"> {user?.firstName}</h5>
                <p className="text-muted mb-0">
                  <i className="mdi mdi-circle text-success align-middle me-2" /> Active
                </p>
              </div>
            </div>
          </div>

          <div className="search-box chat-search-box py-4">
            <div className="position-relative">
              <Input onKeyUp={searchUsers} id="search-user" type="text" placeholder="Search..." onChange={searchUsers} />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>

          <div className="chat-leftsidebar-nav">
            <Nav pills justified>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === "chat", })} onClick={() => {
                  toggleTab("chat");
                }}>
                  <i className="bx bx-chat font-size-20 d-sm-none" />
                  <span className="d-none d-sm-block">Chat</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: activeTab === "contacts", })} onClick={() => {
                  toggleTab("contacts");
                }}>
                  <i className="bx bx-book-content font-size-20 d-sm-none" />
                  <span className="d-none d-sm-block">Contacts</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="py-4">
              <TabPane tabId="chat">
                <div>
                  <h5 className="font-size-14 mb-3">Recent</h5>
                  <ul className="list-unstyled chat-list position-relative" id="recent-list">
                    {
                      isLoading ? <Spinners setLoading={setLoading} /> :

                        <SimpleBar style={{ height: "410px" }}>
                          {(chats || [])?.map((chat: RecentChat) => (
                            <li key={chat.user.id} className={currentRoomId === chat.user.id ? "active" : ''}>
                              <Link to="#" onClick={() => userChatOpen(chat.user)}>
                                <div className="d-flex">
                                  <div className="align-self-center me-3">
                                    <i className={`mdi mdi-circle font-size-10 ${chat.user.online ? "text-success" : "text-warning"}`} />
                                  </div>
                                  <div className={chat.user.avatarUrl ? "align-self-center me-3" : "avatar-xs align-self-center me-3"}>
                                    {chat.user.avatarUrl ? (
                                      <img src={chat.user.avatarUrl} className="rounded-circle avatar-xs" alt="" />
                                    ) : (
                                      <span className="avatar-title rounded-circle bg-primary-subtle text-primary">
                                        {Array.from(chat.user.firstName)[0]}
                                      </span>
                                    )}
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate font-size-14 mb-1">
                                      {chat.user.firstName} {chat.user.lastName}
                                    </h5>
                                    <p className={`text-truncate mb-0 ${chat.lastMessage && !chat.lastMessage.isRead && chat.lastMessage.senderId !== user.id ? "fw-bold" : ""}`}>
                                      {chat.lastMessage?.content}
                                    </p>

                                  </div>

                                  <div className="d-flex flex-column align-items-end">
                                    {chat.lastMessage && (
                                      <div className="font-size-11">
                                        {dayjs(chat.lastMessage.createdAt, 'YYYY-MM-DDTHH:mm:ss.sssZ').fromNow()}
                                      </div>
                                    )}
                                  </div>

                                </div>
                              </Link>
                            </li>
                          ))}
                        </SimpleBar>
                    }
                  </ul>
                </div>
              </TabPane>
              <TabPane tabId="contacts">
                <div>
                  <SimpleBar style={{ height: "410px" }}>
                    <ul className="list-unstyled chat-list">
                      {contacts &&
                        (contacts || [])?.map((contact: UserData) => (
                          <li key={contact.id} className={currentRoomId === contact.id ? "active" : ""}>
                            <Link to="#" onClick={() => {
                              userChatOpen(contact)
                            }}>
                              <h5 className="font-size-14 mb-0">{`${contact.firstName} ${contact.lastName}`}</h5>
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </SimpleBar>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ChatList;