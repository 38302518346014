import React from "react";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { Card } from "reactstrap";
// Image
import user1 from "../../../assets/images/users/user_profile.svg";

import { useAppSelector } from "slices";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const RecentReviews: React.FC = () => {
  const { reviews } = useAppSelector((state) => state.Analytics);

  return (
    <Card className="p-4">
      <h5>Recent reviews</h5>

      {reviews && reviews.length > 0 ? (
        reviews.map((review) => (
          <div key={review?.createdAt} className="d-flex py-3 border-bottom">
            <div className="flex-shrink-0 me-3">
              <img
                src={review?.avatarURL || user1}
                className="avatar-xs rounded-circle"
                alt=" img"
              />
            </div>

            <div className="flex-grow-1">
              <h5 className="mb-2 font-size-15">
                {review?.user?.firstName} {review?.user?.lastName}
              </h5>

              {review?.feedback &&
                Object.keys(review?.feedback).map((key) => (
                  <div key={key}>
                    <strong>
                      {" "}
                      {key
                        .split("-")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                      :{" "}
                    </strong>
                    {typeof review?.feedback[key] === "number"
                      ? Array(review?.feedback[key]).fill(
                          <i
                            className="bx bxs-star"
                            style={{ color: "gold" }}
                          />
                        )
                      : review?.feedback[key]}
                  </div>
                ))}

              <div className="text-muted font-size-12 mt-2">
                <i className="far fa-calendar-alt text-primary me-1"></i>
                {dayjs(review?.["createdAt"]).fromNow()}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="d-flex py-3 border-bottom">No reviews found</div>
      )}
    </Card>
  );
};

export default RecentReviews;
