import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Button,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { acceptInvite } from "slices/auth/accept_invite/thunk";

// import images
import logo from "../../assets/images/logo.png";
import { createSelector } from "reselect";
import { toast } from "react-toastify";
import { resetAcceptInviteMsg } from "slices/auth/accept_invite/reducer";
import GoogleIcon from "../../assets/images/google.svg";
import MicrosoftIcon from "../../assets/images/microsoft.svg";
import mentorshipBg from "../../assets/images/mentorship.png";
import { isEmpty } from "lodash";
import { getPublicConfig } from "slices/thunk";

const AcceptInvite = (props: any) => {
  //meta title
  document.title = "Accept Invite | Mentorgain";

  const dispatch = useDispatch<any>();
  const [show, setShow] = useState(false);
  const [inviteToken, setInviteToken] = useState<any>("");

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Set Your Password"),
    }),
    onSubmit: ({ password }) => {
      dispatch(acceptInvite(password, inviteToken, props.router.navigate));
    },
  });

  const selectProperties = createSelector(
    (state: any) => state.AcceptInvite,
    (acceptInvite) => ({
      acceptInviteError: acceptInvite.acceptInviteError,
      acceptInviteMsg: acceptInvite.acceptInviteMsg,
    })
  );

  const selectPublicConfigProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      publicConfig: global.publicConfig,
    })
  );

  const { publicConfig } = useSelector(selectPublicConfigProperties);
  const { acceptInviteError, acceptInviteMsg } = useSelector(selectProperties);

  useEffect(() => {
    const { router: { location: { search: searchParam = "" } = {} } = {} } =
      props;
    const search = new URLSearchParams(searchParam);

    if (acceptInviteError || acceptInviteMsg) {
      if (acceptInviteError) {
        Array.isArray(acceptInviteError)
          ? toast.error(acceptInviteError[0])
          : toast.error(acceptInviteError);
      }
      if (acceptInviteMsg) toast.success(acceptInviteMsg);
      dispatch(resetAcceptInviteMsg());
    }

    if (isEmpty(publicConfig)) {
      dispatch(getPublicConfig());
    }

    setInviteToken(search.get("token"));
  }, [props, acceptInviteError, acceptInviteMsg, dispatch, publicConfig]);

  const redirectToGoogle = () => {
    const subdomain = window.location.host.split(".")[0];
    window.location.assign(`${process.env.REACT_APP_AUTH_URL}/google/init/${subdomain}`);
  };

  const redirectToMicrosoft = () => {
    window.location.assign(`${process.env.REACT_APP_AUTH_URL}/microsoft/init`);
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome!</h5>
                        <p>Please set user password.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={mentorshipBg} alt="" className="img-fluid pt-4" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="Mentorgain Logo" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>

                  {publicConfig?.enabledProviders && (
                    <>
                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <div className="p-2">
                          {publicConfig?.enabledProviders.includes(
                            "google"
                          ) && (
                              <div className="d-grid">
                                <Button
                                  className="btn btn-soft-primary btn-block"
                                  onClick={redirectToGoogle}
                                >
                                  <img
                                    src={GoogleIcon}
                                    alt="Continue with Google"
                                    className="mx-2"
                                    style={{ width: "16px" }}
                                  />
                                  Continue With Google
                                </Button>
                              </div>
                            )}

                          {publicConfig?.enabledProviders.includes(
                            "microsoft"
                          ) && (
                              <div className="mt-3 d-grid">
                                <Button
                                  className="btn btn-soft-primary btn-block"
                                  onClick={redirectToMicrosoft}
                                >
                                  <img
                                    src={MicrosoftIcon}
                                    alt="Continue with Microsoft"
                                    className="mx-2"
                                    style={{ width: "16px" }}
                                  />
                                  Continue With Microsoft
                                </Button>
                              </div>
                            )}
                        </div>
                      </div>
                      {publicConfig?.enabledProviders?.length > 1 && publicConfig?.enabledProviders.includes("email-password") && (
                        <div className="p-2">
                          <div className="separator">OR</div>
                        </div>
                      )}
                    </>
                  )}
                  {publicConfig?.enabledProviders && publicConfig?.enabledProviders.includes("email-password") && (
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={show ? "text" : "password"}
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                !!(
                                  validation.touched.password &&
                                  validation.errors.password
                                )
                              }
                            />
                            <button
                              onClick={() => setShow(!show)}
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          {validation.touched.password &&
                            validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-3">
                          <Col>
                            <button
                              className="btn btn-primary w-md "
                              type="submit"
                            >
                              Accept Invite
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Mentorgain.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AcceptInvite);
