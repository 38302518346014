
import React, { useState, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import withRouter from "Components/Common/withRouter";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { toast } from "react-toastify";
import { addNewUser, uploadCsv, downloadCsv } from "../../../slices/admin/thunk";

const ManageMembers = () => {
  document.title = "Invite Users | Mentorgain";

  const dispatch = useDispatch<any>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isUploading, setUploading] = useState<boolean>(false);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [contact] = useState<any>();
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please Enter Your First Name"),
    lastName: Yup.string().required("Please Enter Your Last Name"),
    email: Yup.string().email("Invalid email address").required("Please Enter Your Email"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: (contact && contact.firstName) || "",
      lastName: (contact && contact.lastName) || "",
      email: (contact && contact.email) || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const newUser = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };
      setLoading(true);
      await dispatch(addNewUser(newUser));
      formik.resetForm();
      setLoading(false);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUploadCsv = () => {
    if (!file) {
      toast.error("Please choose a CSV file.", { autoClose: 3000 });
      return;
    }

    const fileName = file.name;
    const fileExtension = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();

    if (fileExtension !== "csv") {
      toast.error("Invalid file format. Please choose a CSV format.", { autoClose: 3000 });
      return;
    }

    setUploading(true);
    dispatch(uploadCsv(file)).finally(() => {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setFile(null);
    });
  };
  const handleDownloadCsv = async () => {
    setDownloading(true);
    await dispatch(downloadCsv());
    setDownloading(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Administration" breadcrumbItem="Invited Users" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4>Invite User</h4>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label>First Name</Label>
                          <Input
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName || ""}
                            invalid={!!(formik.touched.firstName && formik.errors.firstName)}
                          />
                          {formik.touched.firstName && formik.errors.firstName ? (
                            <FormFeedback type="invalid">
                              {String(formik.errors.firstName)}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label>Last Name</Label>
                          <Input
                            name="lastName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName || ""}
                            invalid={!!(formik.touched.lastName && formik.errors.lastName)}
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <FormFeedback type="invalid">
                              {String(formik.errors.lastName)}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label>Email</Label>
                          <Input
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email || ""}
                            invalid={!!(formik.touched.email && formik.errors.email)}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <FormFeedback type="invalid">
                              {String(formik.errors.email)}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <Button
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                            className="save-user btn-primary px-3"
                          >
                            {isLoading ? "Inviting..." : "Invite"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Card>
            <CardBody>
              <Row className="mt-4">
                <Col xs={12}>
                  <Input
                    type="file"
                    accept=".csv"
                    innerRef={fileInputRef}
                    onChange={handleFileChange}
                    className="w-100"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={6} className="d-flex justify-content-start">
                  <Button
                    color="secondary"
                    onClick={handleUploadCsv}
                    disabled={!file || isUploading}
                  >
                    {isUploading ? "Uploading..." : "Upload file"}
                  </Button>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    onClick={handleDownloadCsv}
                    disabled={isDownloading}
                  >
                    {isDownloading ? "Downloading..." : "Download Template"}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ManageMembers);
