import { toast } from "react-toastify";
import { APIClient } from "../../../src/helpers/api_helper";
import { setAllMentors, setMentorSubmittedSurvey } from "./reducer";
import {ONBOARDING, USER_PROFILE_STATUS} from "../../Components/constants/userData";
import dayjs from "dayjs";

const api = new APIClient();

export const setSlots = (data: any, onProfilePage: boolean) => async (dispatch: any) => {
    try {
    const response = await api.create('/api/v1/my/availability-slots', data);

      if (response) {
        toast.success("Slots saved successfully", { autoClose: 2000 });
        if (!onProfilePage) {
          await api.create(`/api/v1/user-config/${ONBOARDING}`, {
          mode: 'UPDATE',
            value: {
            [USER_PROFILE_STATUS.availability_set]: true
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
}

export const getAllMentors = (type: any) => async (dispatch: any) => {
  try {
    const data = await api.get('/api/v1/search', {
      'filter.profileType': 'Mentor',
    });
    if (data) {
      dispatch(setAllMentors(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export const filterMentors = (data: any) => async (dispatch: any) => {
  try {
    const { filterType, filterValue } = data;
    const response = await api.get('/api/v1/search', { [`filter.${filterType}`]: filterValue });
    if (response) {
      dispatch(setAllMentors(response));
    }
  } catch (error) {
    console.error(error);
  }
}

export const applyMultipleFilters = (data: any) => async (dispatch: any) => {
  try {
    const { filterTypes, filterValues } = data;
    const newFilterTypes = [...filterTypes, 'profileType'];
    const newFilterValues = [...filterValues, 'Mentor'];
    const query = newFilterTypes.reduce((acc: any, filterType: any, index: number) => {
        acc[`filter.${filterType}`] = newFilterValues[index];
        return acc;
    }, {});
    const response = await api.get('/api/v1/search', query);
    if (response) {
      dispatch(setAllMentors(response));
    }
  } catch (error) {
    console.error(error);
  }
}

export const getMatchedMentors = () => async (dispatch: any) => {
  try {
    const data = await api.get('/api/v1/search/matched', {
      'filter.profileType': 'Mentor',
    });
    if (data) {
      dispatch(setAllMentors(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export const fetchMentorSessions = (id: any) => async (dispatch: any) => {
  try {
    const temp = "9adb59b5-6fac-4232-8b25-21bb5e1e0910"
    const data = await api.get(`/api/v1/users/${temp}/availability-slots`, null);

    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSlots = (data: any) => async (dispatch: any) => {
  const { userId, from, to } = data;
  try {
    const response = await api.get(`/api/v1/users/${userId}/slots`, {
      from: from,
      to: to
    });
    if (response) {
      const now = dayjs();
      const currentDate = now.format('YYYY-MM-DD');

      if (response.availableSlots.length && currentDate === response.availableSlots[0].date) {
        const currentHour = now.hour();
        const currentMinute = now.minute();
        const currentTimeInMinutes = currentHour * 60 + currentMinute;

        const filteredSlots = response.availableSlots[0].slots.filter(
          (slot) => {
            const [hour, minute] = slot.start.split(':').map(Number);
            const slotTimeInMinutes = hour * 60 + minute;
            return slotTimeInMinutes >= currentTimeInMinutes;
          }
        );

        response.availableSlots[0].slots = filteredSlots;
      }

      return response;
    } else {
      throw new Error("Failed to fetch slots");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const fetchMentorSubmittedSurvey = () => async (dispatch: any) => {
  try {
    const data = await api.get("/api/v1/onboarding/profile/mentor", {});
    if (data.status) {
      dispatch(setMentorSubmittedSurvey(data.profile));
    }
  } catch (error) {
    console.log(error);
  }
}
