import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  changePasswordSuccessMsg: null,
  changePasswordError: null,
};

const changePasswordSlice = createSlice({
  name: "changepwd",
  initialState,
  reducers: {
    userChangePasswordSuccess(state, action) {
      state.changePasswordSuccessMsg = action.payload;
    },
    userChangePasswordError(state, action) {
      state.changePasswordError = action.payload;
    },
    resetChangePasswordFlag(state) {
      state.changePasswordError = null;
      state.changePasswordSuccessMsg = null;
    },
  },
});

export const {
  userChangePasswordSuccess,
  userChangePasswordError,
  resetChangePasswordFlag,
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
