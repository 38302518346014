import React, { useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { setSlots } from "slices/mentor/thunk";
import Calendar from "./Calendar";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { isEmpty } from "lodash";
import { getMyAvailability, getUserConfig } from "slices/onboarding/thunk";
import { useNavigate } from "react-router-dom";
import Pages404 from "pages/Utility/pages-404";
import {
  checkOverlaps,
  hasEmptySlot,
  hasInvalidSlot,
} from "../../helpers/functions";
import dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import { ONBOARDING } from "Components/constants/userData";

const Availability = ({ showCalendar = true, onProfilePage = false }) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const DAYS = useMemo(
    () => [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ],
    []
  );
  const [selectedDays, setSelectedDays] = useState<any[]>([]);
  const [hasOverlapError, setHasOverlapError] = useState<boolean>(false);
  const [hasPastTimeError, setHasPastTimeError] = useState<boolean>(false);
  const [hasEmptySlotError, setHasEmptySlotError] = useState<boolean>(false);
  const [slotCount, setSlotCount] = useState<number>(0);

  const selectAvailabilityProperties = createSelector(
    (state: any) => state.Onboarding,
    (onboarding) => ({
      myAvailability: onboarding.myAvailability,
      calendarStatus: onboarding.calendarStatus,
    })
  );
  const { myAvailability, calendarStatus } = useSelector(
    selectAvailabilityProperties
  );

  const selectProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
      userConfig: global.userConfig,
    })
  );
  const { userConfig, user } = useSelector(selectProperties);

  useEffect(() => {
    dispatch(getMyAvailability());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(myAvailability)) {
      setSelectedDays(
        DAYS.map((day) => {
          return {
            day,
            slots: myAvailability[day]?.slots || [],
            enabled: !!myAvailability[day]?.slots?.length,
          };
        })
      );
    } else {
      setSelectedDays([
        { day: "sunday", slots: [], enabled: false },
        { day: "monday", slots: [], enabled: false },
        { day: "tuesday", slots: [], enabled: false },
        { day: "wednesday", slots: [], enabled: false },
        { day: "thursday", slots: [], enabled: false },
        { day: "friday", slots: [], enabled: false },
        { day: "saturday", slots: [], enabled: false },
      ]);
    }
  }, [myAvailability, DAYS, user.timezone]);

  const addDay = (day: string, currentState: boolean) => {
    const dayIndex = selectedDays.findIndex(
      (selectedDay) => selectedDay.day === day
    );
    if (dayIndex === -1) {
      const newDay = { day, slots: [{ start: "", end: "" }], enabled: true };
      setSelectedDays([...selectedDays, newDay]);
    } else {
      const updatedDays = [...selectedDays];
      updatedDays[dayIndex].enabled = !updatedDays[dayIndex].enabled;
      if (!currentState) {
        updatedDays[dayIndex].slots = [
          ...updatedDays[dayIndex].slots,
          { start: "", end: "" },
        ];
      } else {
        updatedDays[dayIndex].slots = [];
      }
      setSelectedDays(updatedDays);
    }
  };

  const addSlot = (dayIndex: number) => {
    const newSelectedDays = [...selectedDays];
    const selectedSlots = [
      ...newSelectedDays[dayIndex].slots,
      { start: "", end: "" },
    ];
    newSelectedDays[dayIndex].slots = selectedSlots;
    setSelectedDays(newSelectedDays);
  };

  const deleteSlot = (dayIndex: number, slotIndex: number) => {
    const newSelectedDays = [...selectedDays];
    newSelectedDays[dayIndex].slots = newSelectedDays[dayIndex].slots.filter(
      (slot: any, index: number) => index !== slotIndex
    );
    if (newSelectedDays[dayIndex].slots.length === 0) {
      newSelectedDays[dayIndex].enabled = false;
    }
    setSelectedDays(newSelectedDays);
  };

  const handleTimeChange = (
    dayIndex: number,
    slotIndex: number,
    field: string,
    value: string
  ) => {
    const newSelectedDays = JSON.parse(JSON.stringify(selectedDays));
    newSelectedDays[dayIndex].slots[slotIndex][field] = value;
    setSelectedDays(newSelectedDays);
  };

  const handleSubmitSlots = async () => {
    let slotsData = {};

    DAYS.forEach((day) => {
      const selectedDay = selectedDays.find((d) => d.day === day);
      if (selectedDay) {
        const filteredTimeSlots = selectedDay.slots.filter(
          (slot) => slot.start !== "" && slot.end !== ""
        );
        slotsData[day] = {
          enabled: filteredTimeSlots.length > 0,
          slots: filteredTimeSlots,
        };
      } else {
        slotsData[day] = {
          enabled: false,
          slots: [],
        };
      }
    });
    await dispatch(setSlots(slotsData, onProfilePage));
    await dispatch(getMyAvailability());
  };

  useEffect(() => {
    const hasOverlapErrors: boolean[] = [];
    const hasInvalidSlotErrors: boolean[] = [];
    const hasEmptySlotError: boolean[] = [];
    let slotForDays = 0;
    DAYS.forEach((day) => {
      const selectedDay = selectedDays.find((d) => d.day === day);
      if (selectedDay) {
        hasOverlapErrors.push(checkOverlaps(selectedDay.slots));
        hasInvalidSlotErrors.push(hasInvalidSlot(selectedDay.slots));
        hasEmptySlotError.push(hasEmptySlot(selectedDay.slots));
        slotForDays += selectedDay.slots.filter(
          (slot) => slot.start !== "" && slot.end !== ""
        ).length;
      }
    });
    setHasOverlapError(hasOverlapErrors.some((error) => error));
    setHasPastTimeError(hasInvalidSlotErrors.some((error) => error));
    setHasEmptySlotError(hasEmptySlotError.some((error) => error));
    setSlotCount(slotForDays);
  }, [selectedDays, DAYS]);

  const handleBack = () => {
    navigate("/onboarding/create");
  };

  const handleNext = async () => {
    await dispatch(getUserConfig({ key: ONBOARDING }));
    if (userConfig?.onboarding?.onboardingCompleted) navigate("/dashboard");
    else navigate("/onboarding/create/mentor-form");
  };

  return onProfilePage ||
    (userConfig?.onboarding?.userSelections?.mentor &&
      !userConfig?.onboarding?.availabilitySet) ||
    !userConfig?.onboarding?.onboardingCompleted ? (
    <>
      <div className="container">
        <div className="text-center mb-3 mt-3">

          <h4>Set Availability</h4>
        </div>
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="col col-lg-6">
            {hasOverlapError && (
              <div className="alert fade show alert alert-warning" role="alert">
                <i className="mdi mdi-alert-outline me-2"></i>
                Please check for overlapping time slots.
              </div>
            )}
            {hasPastTimeError && (
              <div className="alert fade show alert alert-warning" role="alert">
                <i className="mdi mdi-alert-outline me-2"></i>
                End time must be greater than start time for slots.
              </div>
            )}
            {hasEmptySlotError && (
              <div className="alert fade show alert alert-warning" role="alert">
                <i className="mdi mdi-alert-outline me-2"></i>
                Please select both start time and end time for added slot(s).
              </div>
            )}
          </div>

          <div className="col col-lg-6 text-center">
            {selectedDays.map((selectedDay, index) => (
              <div className="mb-3" key={selectedDay.day}>
                <div className="form-check form-switch d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    id={`customSwitch${index}`}
                    checked={selectedDay.enabled}
                    onChange={() => addDay(selectedDay.day, selectedDay.enabled)}
                  />
                  <span className="text-capitalize">{selectedDay.day}</span>
                </div>
                {selectedDay.enabled && (
                  <div className="mt-2">
                    {selectedDay.slots.map((slot: any, slotIndex: number) => (
                      <div key={slotIndex} className="d-flex justify-content-between align-items-center mb-3">
                        <Flatpickr
                          className="form-control w-45"
                          placeholder="Start time"
                          value={slot.start}
                          onChange={(time) =>
                            handleTimeChange(
                              index,
                              slotIndex,
                              "start",
                              dayjs(time[0]).format("H:mm")
                            )
                          }
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            maxTime: slot.end,
                          }}
                        />
                        <span className="mx-2">To</span>
                        <Flatpickr
                          className="form-control w-45"
                          placeholder="End time"
                          value={slot.end}
                          onChange={(time) =>
                            handleTimeChange(
                              index,
                              slotIndex,
                              "end",
                              dayjs(time[0]).format("H:mm")
                            )
                          }
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            minTime: slot.start,
                          }}
                        />
                        <div className="col col-lg-2 mx-2">
                          <div className="d-flex justify-content-center align-items-center">
                            <i
                              className="bx bx-trash"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteSlot(index, slotIndex)}
                            ></i>
                            <i
                              className="bx bx-plus"
                              style={{ marginLeft: "1rem", cursor: "pointer" }}
                              onClick={() => addSlot(index)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-center mt-1">
          <button
            className="btn btn-primary me-3"
            onClick={() => handleSubmitSlots()}
            disabled={
              hasPastTimeError ||
              hasOverlapError ||
              hasEmptySlotError ||
              slotCount === 0
            }
          >
            Save
          </button>
        </div>
        {showCalendar && <Calendar />}
      </div>
      {!onProfilePage && (
        <div className="d-flex justify-content-center mt-2">
          <div className="col-8 d-flex justify-content-between ">
            <Button
              color="primary"
              className="save-user btn-primary px-3 text-white"
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              color="primary"
              className="save-user btn-primary px-3 text-white ms-3"
              onClick={handleNext}
              disabled={!(calendarStatus.status && !isEmpty(myAvailability))}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </>
  ) : (
    <Pages404 />
  );
};

export default Availability;
