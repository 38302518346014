interface FaqItem {
    question: string;
    answer: string;
}

const mentorFaqItems: FaqItem[] = [
    {
        question: "What am I expected to do?",
        answer: "As a mentor, you are expected to provide your mentee with support and guidance. This interaction can take place through video calls, or face-to-face, depending on what works best for both parties. Collaborate with your mentee to determine the most beneficial form of support—whether that’s providing specific feedback, offering career advice, sharing technical knowledge, or giving personal encouragement."
    },
    {
        question: "How do I start the first meeting?",
        answer: "The first meeting should focus on establishing the foundation of the mentoring relationship. Discuss your mentee's goals, preferred communication methods, and expectations. Set clear objectives and agree on how often you will meet and the format of those meetings (in-person, virtual, etc.)."
    },
    {
        question: "What if I don’t have the expertise to help my mentee with a particular issue?",
        answer: "It’s okay to acknowledge areas where you may not have expertise. In such cases, offer to help your mentee find the right resources or connect them with another expert who might be better suited to assist."
    },
    {
        question: "How long will the commitment be?",
        answer: "We ask mentors to commit to at least a three-month engagement to ensure that mentees can fully benefit from the relationship. There will be some commitments that will be shorter, depending on the nature of help that the mentee seeks."
    },
    {
        question: "Can I mentor more than one mentee at a time?",
        answer: "Yes, you can mentor multiple mentees if you feel confident in your ability to manage your time and provide adequate support to each. However, ensure that each mentee receives the attention and guidance they need."
    },
    {
        question: "Are there any potential risks to me or my company?",
        answer: "No. Mentorship through Mentorgain’s platform is designed to be a professional relationship focused on knowledge sharing and career development. It is not appropriate for mentors to engage in business transactions with their mentees. The relationship should remain centered on providing general information, advice, and support."
    },
    {
        question: "The relationship is not going well, what should I do?",
        answer: "If you encounter challenges in your mentoring relationship, we encourage you to discuss the situation openly with your mentee. If the issue persists, please contact admin for further assistance."
    },
    {
        question: "What is the role of the Mentorgain platform in the mentorship process?",
        answer: "Mentorgain provides a secure, user-friendly platform that facilitates mentor-mentee matching, communication, and progress tracking. It helps streamline the mentorship process, making it easier for both parties to focus on the relationship and growth."
    },
    {
        question: "How does confidentiality work in the mentoring relationship?",
        answer: "Both mentors and mentees are expected to maintain confidentiality regarding discussions within the mentoring relationship. If there’s a need to discuss certain matters with others, it should be done with mutual consent and respect."
    },
];

const menteeFaqItems: FaqItem[] = [
    {
        question: "How do I choose a Mentor?",
        answer: "Start by completing your onboarding with detailed information about the type of support you’re seeking. Then, browse through the list of available mentors on the platform (manual search, search from automated match or admin match) and create a mentoring request. It’s important to choose a mentor who is not your direct reporting manager or within your immediate hierarchy to maintain an unbiased and open relationship."
    },
    {
        question: "How should I prepare for my first meeting with my mentor?",
        answer: "Before your first meeting, reflect on your goals, challenges, and what you hope to gain from the mentoring relationship. Prepare to discuss these with your mentor, and come with specific questions or topics you’d like to explore."
    },
    {
        question: "What should I do if I feel overwhelmed by my mentor’s feedback?",
        answer: "If you find the feedback overwhelming, it’s important to communicate this to your mentor. You can work together to break down the feedback into more manageable steps or prioritize the areas of focus."
    },
    {
        question: "How can I get the most out of my mentorship?",
        answer: "Be proactive in your mentoring relationship by setting clear goals, asking thoughtful questions, and actively seeking feedback. Take responsibility for your learning and apply the advice and insights you receive to your work and personal development."
    },
    {
        question: "How long will the mentors be available?",
        answer: "The mentors will ideally commit to a three-month period to ensure that you gain the full benefit of the relationship. There will be instances where you are looking for shorter commitments or one-time commitments, we request you to be specific on those."
    },
    {
        question: "The relationship is not going well, what should I do?",
        answer: "If you encounter issues in the mentoring relationship, we encourage you to address them with your mentor directly. If the problem persists, please contact admin. We will do our best to help resolve the situation positively and satisfactorily."
    },
    {
        question: "What is the role of the Mentorgain platform in the mentorship process?",
        answer: "Mentorgain provides a secure, user-friendly platform that facilitates mentor-mentee matching, communication, and progress tracking. It helps streamline the mentorship process, making it easier for both parties to focus on the relationship and growth."
    },
    {
        question: "How does confidentiality work in the mentoring relationship?",
        answer: "Both mentors and mentees are expected to maintain confidentiality regarding discussions within the mentoring relationship. If there’s a need to discuss certain matters with others, it should be done with mutual consent and respect."
    },
];

export { mentorFaqItems, menteeFaqItems}; 
export type { FaqItem };
