import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../Common/ChartDynamicColor";

const HorizontalBarChart = ({ data, categories, dataColors }: any) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      data,
    },
  ];
  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },

    colors: spineareaChartColors,
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      borderColor: "#f1f1f1",
    },
    xaxis: {
      title: {
        offsetY: -20,
        text: "Rating Count",
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        rotate: 0,
        show: false,
      },
      categories,
    },
    yaxis: {
      title: {
        text: "Ratings",
      },
      axisBorder: {
        show: false,
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="bar" />;
};

export default HorizontalBarChart;
