import React, { useState } from "react";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";

import HorizontalBarChart from "Components/charts/HorizontalBarChart";
import { useAppSelector } from "slices";
import { convertRatingToHumanReadable } from "slices/global/utils";

const ReviewStatistics: React.FC = () => {
  const { ratingsDistribution } = useAppSelector((state) => state.Analytics);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [selectedRating, setSelectedRating] = useState("overall-satisfaction");

  return (
    <Card>
      <CardBody>
        <Row>
          <Row>
            <h5>Ratings</h5>
          </Row>
          <Row>
            {ratingsDistribution && (
              <>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>
                    {convertRatingToHumanReadable(selectedRating)}
                    <i
                      className="mdi mdi-chevron-down d-none d-xl-inline-block"
                      style={{ marginLeft: "5px" }}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(ratingsDistribution?.distribution).map(
                      (key) => (
                        <DropdownItem
                          key={key}
                          onClick={() => setSelectedRating(key)}
                        >
                          {convertRatingToHumanReadable(key)}
                        </DropdownItem>
                      )
                    )}
                  </DropdownMenu>
                </Dropdown>
              </>
            )}
          </Row>
          <Row>
            <HorizontalBarChart
              data={Object.values(
                ratingsDistribution?.distribution?.[selectedRating] || {}
              )}
              categories={Object.keys(
                ratingsDistribution?.distribution?.[selectedRating] || {}
              )}
              dataColors='["--bs-success"]'
            />
          </Row>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ReviewStatistics;
