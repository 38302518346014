import React, { useEffect, useState } from "react"
import withRouter from "Components/Common/withRouter";
import { Navigate } from "react-router-dom";
import {toast} from "react-toastify";
import {APIClient} from "../../helpers/api_helper";
import LoadingFullScreen from "../../Components/LoadingFullScreen";

const api = new APIClient();
const VerifyEmail = (props) => {
  const { router: {
    location: {
      search: searchParam = ''
    } = {}
  } = {} } = props;
  const search = new URLSearchParams(searchParam);
  const token = search.get('token')
  const [verified, setVerified] = useState(false)
  useEffect(() => {
    api.create(`/api/v1/onboarding/verify-email/${token}`, {}).then((response: any) => {
      setVerified(true)
      toast.success("Email verified. Please wait for admin approval.")
    }).catch((error) => {
      toast.error(error.message || "An error occurred while verifying your email. Please try again.")
    });
  }, [token])

  if (verified) {
    return <Navigate to="/login" />;
  }

  return <LoadingFullScreen title='Just a moment' subtitle='Verifying email...' />;
}


export default withRouter(VerifyEmail)
