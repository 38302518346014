// import { createSlice } from "@reduxjs/toolkit";

// export const initialState = {
//   error: "",
//   success: "",
//   user: {}
// };

// const ProfileSlice = createSlice({
//   name: "Profile",
//   initialState,
//   reducers: {
//     profileSuccess(state, action) {
//       state.user = action.payload
//       state.success = '';
//     },
//     profileError(state, action) {
//       state.error = action.payload
//     },
//     editProfileChange(state) {
//       state = { ...state };
//     },
//     resetProfileFlagChange(state) {
//       state.success = ""
//     }
//   },
// });

// export const {
//   profileSuccess,
//   profileError,
//   editProfileChange,
//   resetProfileFlagChange
// } = ProfileSlice.actions

// export default ProfileSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  error: "",
  success: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    getUserProfile(state, action) {
      state.user = action.payload;
    },
    editUserProfile(state, action) {
      state.user = action.payload;
      state.success = "Profile updated successfully";
    },
    userAcceptInviteError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { getUserProfile, editUserProfile } = profileSlice.actions;

export default profileSlice.reducer;
