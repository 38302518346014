import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchMySessions, fetchRequestedSessions } from "./thunk";

interface SessionListState {
  sessionProfile: any;
  requestedSessions: any;
  sessions: any;
  page: number;
  perPage: number;
}

const initialState: SessionListState = {
  sessionProfile: null,
  requestedSessions: [],
  sessions: [],
  page: 1,
  perPage: 10,
};

const sessionSlice = createSlice({
  name: "SessionList",
  initialState,
  reducers: {
    fetchProfileSuccess(state, action: any) {
      state.sessionProfile = action.payload;
    },
    setSessionRequests(state, action: any) {
      state.requestedSessions = action.payload;
    },
    setSessions(state, action: any) {
      state.sessions = action.payload;
    },
    setPageForSessions(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setPerPageForSessions(state, action: PayloadAction<number>) {
      state.perPage = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchMySessions.fulfilled, (state, action) => {
      state.sessions = action.payload.sessions;
    });
    builder.addCase(fetchMySessions.rejected, (state, action) => {
      state.sessions = [];
    });
    builder.addCase(fetchRequestedSessions.fulfilled, (state, action) => {
        state.requestedSessions = action.payload.requests;
    });
    builder.addCase(fetchRequestedSessions.rejected, (state, action) => {
      state.requestedSessions = [];
    });
  },
});

export const {
  fetchProfileSuccess,
  setSessionRequests,
  setSessions,
  setPerPageForSessions,
  setPageForSessions,
} = sessionSlice.actions;

export default sessionSlice.reducer;
