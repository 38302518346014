import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import {
  cancelRequestedSession,
  cancelScheduledSession,
  fetchCanceledSessions,
  fetchMySessions,
  rejectRequestedSession,
} from "slices/session/thunk";
import SessionList from "./SessionList";
import RescheduleSessionModal from "Components/Common/rescheduleSessionModal";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useLocation } from "react-router-dom";
import { setPerPageForSessions } from "slices/session/reducer";
import Emitter from "../../helpers/emitter";
import { SESSION_OR_REQUEST_RESCHEDULED } from "../../helpers/events";

const AcceptedSessions = (props: any) => {
  const { type, contactId } = props;
  const dispatch = useDispatch<any>();
  const [showRescheduleModal, setShowRescheduleModal] =
    useState<boolean>(false);
  const [declineModal, setDeclineModal] = useState<boolean>(false);
  const [selectedSession, setSelectedSession] = useState<any>(null);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [isMentor, setIsMentor] = useState<boolean>(false);
  const [reasonForDecline, setReasonForDecline] = useState<string>("");
  const [isRequestCancel, setIsRequestCancel] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      dispatch(setPerPageForSessions(4));
    } else {
      dispatch(setPerPageForSessions(10));
    }
    if (type === "CANCELED") {
      dispatch(fetchCanceledSessions(contactId));
    } else {
      dispatch(fetchMySessions({ status: type, contactId }));

    }
    Emitter.on(SESSION_OR_REQUEST_RESCHEDULED, () => {
      if (type !== "CANCELED") {
        dispatch(fetchMySessions({ status: type, contactId }));
      }
    });
    return () => {
      Emitter.off(SESSION_OR_REQUEST_RESCHEDULED, () => {
        if (type !== "CANCELED") {
          dispatch(fetchMySessions({ status: type, contactId }));
        }
      });
    };
  }, [dispatch, type, location, contactId]);

  const selectSessions = createSelector(
    (state: any) => state.SessionList,
    (session) => ({
      sessions: session.sessions,
    })
  );
  const { sessions } = useSelector(selectSessions);

  const handleOpenModal = (id: any) => {
    setSelectedSession(id);
  };

  const handleRescheduleModalOpen = (id: any, userId: any, isMentor: any) => {
    setSelectedSession(id);
    setShowRescheduleModal(true);
    setSelectedUserId(userId);
    setIsMentor(isMentor);
  };

  const handleDeclineRequest = (id: any, type: any) => {
    if (type === "DECLINE") {
      setIsRequestCancel(true);
    }
    setSelectedSession(id);
    setDeclineModal(true);
  };

  const handleRequestReject = () => {
    dispatch(
      rejectRequestedSession({
        requestId: selectedSession,
        status: "reject",
        reason: reasonForDecline,
      })
    );
    setDeclineModal(false);
  };

  const handleRequestCancel = () => {
    dispatch(
      cancelRequestedSession({
        requestId: selectedSession,
        reason: reasonForDecline,
      })
    );
    setDeclineModal(false);
  };

  const handleScheduledCancel = () => {
    if (selectedSession) {
      dispatch(
        cancelScheduledSession({
          requestId: selectedSession,
          reason: reasonForDecline,
        })
      );
    }
    setDeclineModal(false);
  };

  const toggleRescheduleModal = () => {
    setShowRescheduleModal(!showRescheduleModal);
  };

  return (
    <>
      {selectedSession && (
        <>
          <RescheduleSessionModal
            showModal={showRescheduleModal}
            selectedSession={selectedSession}
            selectedUserId={selectedUserId}
            toggleRescheduleModal={toggleRescheduleModal}
            rescheduleType={type}
            isMentor={isMentor}
          />
          <Modal
            isOpen={declineModal}
            toggle={() => setDeclineModal(!declineModal)}
            centered
          >
            <ModalHeader toggle={() => setDeclineModal(!declineModal)}>
              Reason for{" "}
              {isRequestCancel
                ? "Cancel"
                : type === "REQUESTED"
                ? "decline"
                : "cancel"}
            </ModalHeader>
            <ModalBody>
              <Input
                type="textarea"
                value={reasonForDecline}
                onChange={(e) => setReasonForDecline(e.target.value)}
              ></Input>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <Button
                  className="btn btn-sm btn-danger"
                  onClick={
                    isRequestCancel
                      ? handleRequestCancel
                      : type === "REQUESTED"
                      ? handleRequestReject
                      : handleScheduledCancel
                  }
                >
                  {isRequestCancel
                    ? "Cancel"
                    : type === "REQUESTED"
                    ? "Decline"
                    : "Cancel"}{" "}
                  Request
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
      {sessions.length === 0 ? (
        <div className="text-center">No sessions found</div>
      ) : (
        <SessionList
          listData={sessions || []}
          openModal={handleOpenModal}
          type={type}
          handleRescheduleModalOpen={handleRescheduleModalOpen}
          handleDeclineRequest={handleDeclineRequest}
          contactId={contactId}
        />
      )}
    </>
  );
};

export default AcceptedSessions;
