import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";
import withRouter from "./withRouter";
import { createSelector } from 'reselect';

// users
import user1 from "../../assets/images/users/user_profile.svg";

import { useSelector, useDispatch } from "react-redux";
import { onEdit } from "../../slices/thunk";

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch<any>();

  const [username, setUsername] = useState("Admin");

  const selectProfileProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
    })
  );

  const { user } = useSelector(selectProfileProperties);

  useEffect(() => {
    if (user) {
      setUsername(user?.firstName);
    }
  }, [user]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user.avatarURL || user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username || "admin"}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            onClick={(event: any) => {
              event.preventDefault();
              dispatch(onEdit(user, props.router.navigate)); 
            }}
          >
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(ProfileMenu));

