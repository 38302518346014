import { APIClient } from "../../helpers/api_helper";
import { SetMentees, setMenteeSubmittedSurvey } from "./reducer";

const api = new APIClient();

export const getMentees = (user_id: any) => async (dispatch: any) => {
  try {
    const data = await api.get('/api/v1/mentors', {
      user_id: user_id,
    });

    if (data) {
      dispatch(SetMentees(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export const fetchMenteeSubmittedSurvey = () => async (dispatch: any) => {
  try {
    const data = await api.get("/api/v1/onboarding/profile/mentee", {});
    if (data.status) {
      dispatch(setMenteeSubmittedSurvey(data.profile));
    }
  } catch (error) {
    console.log(error);
  }
}
