import { userAcceptInviteSuccess, userAcceptInviteError } from "./reducer"
import { APIClient } from "../../../helpers/api_helper";
import { setLoggedInUser } from "slices/thunk";
const api = new APIClient();

export const acceptInvite = (password: any, token: string, navigate: any) => async (dispatch: any) => {
  if (!token) {
    dispatch(userAcceptInviteError("Token is missing"));
    return;
  }
  try {
    const data = await api.create('/api/v1/onboarding/accept-invite', {
      password: password,
      token: token
    });

    if (data && data["status"]) {
      dispatch(
        userAcceptInviteSuccess("Invite Accepted Successfully. Redirecting to the Onboarding page."
        )
      );
      dispatch(setLoggedInUser(data));
      navigate('/dashboard');
    }
  } catch (error: any) {
    dispatch(userAcceptInviteError(error.message));
  }
};
