import { createContext, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "slices/thunk";
import { createSelector } from "reselect";

const AuthContext = createContext({ isLoggedIn: false, loading: false });

interface GlobalState {
  Global: {
    user: any;
    userLoggedInCheckDone: boolean;
  };
}

const AuthProvider = ({ children }) => {
  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
    (state: GlobalState) => state.Global,
    (global) => ({
      user: global.user,
      userLoggedInCheckDone: global.userLoggedInCheckDone,
    })
  );

  const { user, userLoggedInCheckDone } = useSelector(selectProperties);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const isLoggedIn = !!user;
  const loading = !userLoggedInCheckDone;

  return (
    <AuthContext.Provider value={{ isLoggedIn, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
