import {
  userChangePasswordSuccess,
  userChangePasswordError,
  resetChangePasswordFlag,
} from "./reducer";
import { APIClient } from "../../../helpers/api_helper";

const api = new APIClient();

export const userChangePassword = (values: any) => async (dispatch: any) => {
  try {
    const data: any = await api.create("/api/v1/auth/password/change", {
      oldPassword: values.currentPassword,
      password: values.newPassword,
    });

    if (data) {
      dispatch(userChangePasswordSuccess(data.message));
    }
  } catch (forgetError: any) {
    dispatch(userChangePasswordError(forgetError.message));
  }
};

export const resetChangePasswordMsg = () => {
  try {
    const response = resetChangePasswordFlag();
    return response;
  } catch (error) {
    return error;
  }
};
