import { combineReducers } from "redux";

// Front
import GlobalReducer from "./global/reducer";
import LoginReducer from "./auth/login/reducer";
import ProfileReducer from "./auth/profile/reducer";
import ForgotPasswordReducer from "./auth/forgetpwd/reducer";
import AccountReducer from "./auth/register/reducer";
import AdminReducer from "./admin/reducer";
import AcceptInviteReducer from "./auth/accept_invite/reducer";
import ResetPasswordReducer from "./auth/reset_password/reducer";
import OnboardingReducer from "./onboarding/reducer";
import MentorReducer from "./mentor/reducer";
import MenteeReducer from "./mentee/reducer";
import ChatsReducer from "./chats/reducer";
import UserProfile from "./profile/reducer";
import SessionListReducer from "./session/reducer";
import AnalyticsReducer from "./analytics/reducer";
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ChangePasswordReducer from "./auth/change_password/reducer";

const rootReducer = combineReducers({
  Global: GlobalReducer,
  Login: LoginReducer,
  Admin: AdminReducer,
  Profile: ProfileReducer,
  ForgetPassword: ForgotPasswordReducer,
  Account: AccountReducer,
  AcceptInvite: AcceptInviteReducer,
  ResetPassword: ResetPasswordReducer,
  Onboarding: OnboardingReducer,
  Mentor: MentorReducer,
  Mentee: MenteeReducer,
  Chats: ChatsReducer,
  UserProfile: UserProfile,
  SessionList: SessionListReducer,
  Analytics: AnalyticsReducer,
  ChangePassword: ChangePasswordReducer,
});

export default rootReducer;

export const store = configureStore({ reducer: rootReducer, devTools: true });
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
