// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange
} from "./reducer";
import { APIClient } from "../../../helpers/api_helper";

const api = new APIClient();

// Is user register successful then direct plot user in redux.
export const registerUser = (user: any) => async (dispatch: any) => {
  try {
    const data: any = await api.create("/api/v1/auth/register", user);
    dispatch(registerUserSuccessful(data));
  } catch (error) {
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = () => {
  try {
    const response = apiErrorChange("");
    return response;
  } catch (error) {
    return error;
  }
};