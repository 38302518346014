import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action

// import images
import logo from "../../assets/images/logo.png";
import { createSelector } from "reselect";
import { toast } from "react-toastify";
import { resetPassword, resetResetPasswordMsg } from "slices/auth/reset_password/thunk";
import mentorshipBg from "../../assets/images/mentorship.png";

const ResetPasswordPage = (props) => {
  //meta title
  document.title = "Reset Password | Mentorgain";
  const [resetToken, setResetToken] = useState<any>('');
  const [show, setShow] = useState(false);
  
  const dispatch = useDispatch<any>();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Set Your Password"),
    }),
    onSubmit: (password) => {
      dispatch(resetPassword(password.password, resetToken, props.router.navigate));
    },
  });

  const selectProperties = createSelector(
    (state: any) => state.ResetPassword,
    (resetPassword) => ({
      resetPasswordError: resetPassword.resetPasswordError,
      resetPasswordMsg: resetPassword.resetPasswordMsg,
    })
  );

  const { resetPasswordError, resetPasswordMsg } = useSelector(selectProperties);

  useEffect(() => {
    const { router: {
      location: {
        search: searchParam = ''
      } = {}
    } = {} } = props;
    const search = new URLSearchParams(searchParam);
    if (resetPasswordError || resetPasswordMsg) {
        dispatch(resetResetPasswordMsg());
    }
    setResetToken(search.get('token'));
  }, [props, resetPasswordError, resetPasswordMsg, dispatch]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-light p-4">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Reset Your Password</h5>
                          <p>Create a new password to regain access to your Mentorgain account.</p>
                        </div>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={mentorshipBg} alt="" className="img-fluid pt-4"/>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt="Mentorgain Logo"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {resetPasswordError ? (
                      toast.error(resetPasswordError)
                    ) : null}
                    {resetPasswordMsg ? (
                      toast.success(resetPasswordMsg)
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(validation.touched.password &&
                                  validation.errors.password)
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md mt-3"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Mentorgain.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPasswordPage);
