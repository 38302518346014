import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useCallback, useEffect, useState } from "react";
import {
  postFormData,
  postOnboardingCompleted,
} from "slices/onboarding/thunk";
import { useDispatch, useSelector } from "react-redux";
import Spinners from "Components/Common/Spinner";
import { APIClient } from "../../helpers/api_helper";
import * as SurveyTheme from "survey-core/themes";
import { isEmpty } from "lodash";
import { fetchMenteeSubmittedSurvey } from "slices/mentee/thunk";
import { toast } from "react-toastify";
import { createSelector } from "reselect";

const api = new APIClient();

export const MenteeSurvey = ({ submittedSurvey = {}, profilePage = false }) => {
  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState<any>(null);

  const selectMenteeSurvey = createSelector(
    (state: any) => state.Mentee,
    (mentee) => ({
      submittedMenteeSurvey: mentee.submittedMenteeSurvey,
    })
  );

  const { submittedMenteeSurvey } = useSelector(selectMenteeSurvey);

  const processResponse = useCallback(
    (response: any, commonProfileFields: any = null) => {
      if (response && response.form && response.form.pages) {
        response.form.pages.forEach((page: any) => {
          if (page.elements) {
            page.elements = page.elements.map((element: any) => {
              let entry;
              if (!isEmpty(submittedSurvey)) {
                entry = submittedSurvey[element.name];
              } else if (commonProfileFields !== null) {
                entry = commonProfileFields?.[element.name];
              }
              if (entry !== undefined && entry.length > 0) {
                return { ...element, defaultValue: entry };
              }
              return element;
            });
          }
        });
      }
      return response;
    },
    [submittedSurvey]
  );

  const getMenteeForm = useCallback(async () => {
    const response = await api.get(
      "/api/v1/onboarding/profile/forms/mentee",
      {}
    );

    const { commonProfileFields } = await api.get(
      `/api/v1/onboarding/profile/mentee/common-fields`
    );
    setForm(response);
    if (!isEmpty(submittedSurvey)) {
      setForm(processResponse(response));
    }

    if (isEmpty(submittedSurvey) && commonProfileFields !== null) {
      setForm(processResponse(response, commonProfileFields));
    }
    setIsLoading(false);
  }, [processResponse, submittedSurvey]);

  useEffect(() => {
    getMenteeForm();
    // eslint-disable-next-line
  }, [submittedMenteeSurvey]);

  const startSurvey = (surveyModel: any) => {
    surveyModel.onCompleting.add((survey: any, options: any) => {
      const lastPageAnsweredQues = survey.currentPage.getValue();
      if (Object.keys(lastPageAnsweredQues).length < 2) {
        options.allowComplete = false;
        toast.error("Please select at least 2 skills.");
      }
    });

    surveyModel.onComplete.add(async (survey: any) => {
      survey.completedHtml = "<h4>Your inputs have been saved</h4>";
      await dispatch(postFormData(survey.data, form.formId, "mentee"));
      await dispatch(fetchMenteeSubmittedSurvey());
      if (!profilePage) {
        await dispatch(postOnboardingCompleted());
        window.location.href = "/dashboard";
      }
    });

    return surveyModel;
  };

  if (isLoading || !form) {
    return <Spinners setLoading={setIsLoading} />;
  }

  const survey = new Model(form.form);
  survey.applyTheme(SurveyTheme.SharpLight);

  return <Survey model={startSurvey(survey)} />;
};
