import {Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Flatpickr from "react-flatpickr";
import {useCallback, useEffect, useMemo, useState, useRef} from "react";
import {format, parse} from "date-fns";
import {useDispatch} from "react-redux";
import {getSlots} from "slices/mentor/thunk";
import { requestSession } from "slices/session/thunk";
import Select from 'react-select';
import {APIClient} from "../helpers/api_helper";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {toast} from "react-toastify";
import {isEmpty} from "lodash";
import dayjs from "dayjs";
import {getMissingDates} from "../helpers/date";

const api = new APIClient();

const AvailabilityCalendar = (props: any) => {
  const {isOpen, toggle, mentorId, fetchSlots} = props;
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [bookingEnabled, setBookingEnabled] = useState<any>(false);
  const [availableTime, setAvailableTime] = useState<any>([]);
  const [selectedTime, setSelectedTime] = useState<any>({});
  const [selectedFeatures, setSelectedFeatures] = useState<any>([]);
  const [notes, setNotes] = useState<string>('');
  const [mySkills, setMySkills] = useState<any>([]);
  const [fetchedSlots, setFetchedSlots] = useState<any>([])
  const dispatch = useDispatch<any>();
  const fp = useRef(null);

  const selectProfileProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
    })
  );
  const {user} = useSelector(selectProfileProperties);
  const menteeFields = user.menteeFields;

  const handleDateChange = (date: any) => {
    const formattedDate = format(date[0], 'yyyy-MM-dd');
    setSelectedDate(formattedDate);
    setBookingEnabled(true);
    const daySlots = fetchedSlots.find(fetchSlotsForDay => fetchSlotsForDay.date === formattedDate);
    const time = daySlots?.slots?.[0];
    if (time) {
      setSelectedTime(time.start);
    } else {
      setSelectedTime(null);
    }
    setAvailableTime(daySlots?.slots || []);
  };

  const fetchSlotsForMonth = useMemo(() => {
    return (from, to) => {
      dispatch(getSlots({from, to, userId: mentorId}))
        .then((fetchedSlots) => {
          const firstDay = fetchedSlots['availableSlots'][0];
          const time = firstDay?.slots?.[0];
          if (time) {
            setSelectedTime(time.start);
            setAvailableTime(firstDay.slots);
            setSelectedDate(firstDay.date);
            if (fp.current) {
              // @ts-ignore
              fp.current.flatpickr.setDate(firstDay?.date)
            }
          } else {
            setSelectedTime(null);
            setSelectedDate(null);
            setAvailableTime([])
          }
          setFetchedSlots(fetchedSlots['availableSlots']);
        });
    };
  }, [dispatch, mentorId]);

  const handleMonthChange = (__, ___, calendarConfig) => {
    const currentMonth = dayjs().month()
    let fromDate = dayjs().month(calendarConfig.currentMonth).year(calendarConfig.currentYear).startOf('month').format('YYYY-MM-DD')
    if (currentMonth === calendarConfig.currentMonth) {
      fromDate = dayjs().format('YYYY-MM-DD')
    }
    const toDate = dayjs().month(calendarConfig.currentMonth).year(calendarConfig.currentYear).endOf('month').format('YYYY-MM-DD')
    fetchSlotsForMonth(fromDate, toDate)
  }

  const handleBookSession = () => {
    dispatch(requestSession({
      mentorId,
      date: selectedDate,
      time: selectedTime,
      skills: selectedFeatures.map((feature: any) => feature.value),
      notes,
      mode,
    })).then(() => {
      fetchSlots();
      toggle();
    });

  };

  const convertTo12HourFormat = (time: string) => {
    const parsedTime = parse(time, 'HH:mm', new Date());
    return format(parsedTime, 'hh:mm a');
  };

  const [skills, setSkills] = useState<any>([]);

  const fetchSkills = async () => {
    await api.get('/api/v1/sessions/skills').then((response: any) => {
      const options = response.map((group: any) => ({
        label: group.title,
        name: group.name,
        options: group.choices.map((choice: any) => ({
          label: choice.text,
          value: choice.value
        }))
      }));
      setSkills(options);
    }).catch((error: any) => {
      console.log(error);
      toast.error("Error while fetching skills.")
    });
  }

  const filterSkills = useCallback(() => {
    if (!isEmpty(menteeFields) && skills.length > 0) {
      const arr = skills.map(skill => {
        if (menteeFields[skill.name]) {
          const filteredChoices = skill.options.filter(choice => menteeFields[skill.name].includes(choice.value));
          if (filteredChoices.length > 0) {
            return {label: skill.label, name: skill.name, options: filteredChoices};
          }
        }
        return null;
      }).filter(skill => skill !== null);
      setMySkills(arr);
    }
  }, [menteeFields, skills]);

  useEffect(() => {
    fetchSkills();
    const today = dayjs().format('YYYY-MM-DD')
    const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
    fetchSlotsForMonth(today, endOfMonth);
  }, [fetchSlotsForMonth])

  useEffect(() => {
    filterSkills();
  }, [filterSkills])

  const onChange = (selectedOption) => {
    setSelectedFeatures(selectedOption);
  };

  const [mode] = useState<string>('REMOTE');
  useEffect(() => {
    const isBookingEnabled = selectedDate && selectedTime && selectedFeatures.length > 0 && notes !== '';
    setBookingEnabled(isBookingEnabled);
  }, [selectedDate, selectedTime, selectedFeatures, notes]);

  const datesThatHaveSlots = fetchedSlots.map(fetchSlot => fetchSlot.date)
  let missingDates: string[] = []
  if (fp.current) {
    // @ts-ignore
    const currentYear = fp.current.flatpickr.currentYear
    // @ts-ignore
    const currentMonth = fp.current.flatpickr.currentMonth
    missingDates = getMissingDates(datesThatHaveSlots, currentYear, currentMonth + 1)
  }

  const renderContent = () => (
    <Row>
      <Col md={6}>
        <Flatpickr
          className="form-control"
          ref={fp}
          options={{
            inline: true,
            dateFormat: "Y-m-d",
            minDate: format(new Date(), 'yyyy-MM-dd'),
            defaultDate: selectedDate,
            onChange: handleDateChange,
            onMonthChange: handleMonthChange,
            disable: missingDates,
          }}
          style={{display: "none"}}
        />
        {availableTime.length > 0 && (
          <>
            <Label className="mt-3 mb-0">Available time slots</Label>
            <div className="mb-1">
              <hr style={{border: '0.1px solid #e3e3e3', margin: '5px 0px'}}/>
            </div>
          </>
        )}

        <Row className="mb-3 ms-2" style={{maxHeight: "200px", overflowY: "auto"}}>
          {availableTime.length ? availableTime.map((slotData, index) => (
            <Col key={index} xl={2} sm={2} style={{width: '6rem'}}>
              <div className="mb-3">
                <label className="card-radio-label mb-2">
                  <input
                    type="radio"
                    name="time"
                    className="card-radio-input"
                    value={slotData.start}
                    defaultChecked={index === 0}
                    onChange={() => setSelectedTime(slotData.start)}
                  />
                  <div className="card-radio">
                    <div className="d-flex justify-content-center align-items-center">
                      <span>{convertTo12HourFormat(slotData.start)}</span>
                    </div>
                  </div>
                </label>
              </div>
            </Col>
          )) : <span className="d-block ms-0 "
                     style={{paddingLeft: 0, marginLeft: 0, marginTop: availableTime.length ? '0' : '10px'}}>No slots available</span>}
        </Row>

      </Col>
      <Col md={6}>
        <>
          <Row className="mb-3 justify-content-center">
            <Col md={11}>
              <p className="mb-2">Goals/Skills I want mentoring on</p>
              <Select
                classNamePrefix="select2-selection modal-dialog-scrollable modal-dialog"
                name="features"
                placeholder="Choose..."
                options={mySkills}
                isMulti
                onChange={onChange}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'white',
                    cursor: 'pointer',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'white',
                  }),
                  menuPortal: base => ({...base, zIndex: 9999}),
                  placeholder: styles => ({...styles, marginBottom: '16px'}),
                }}
                menuPortalTarget={document.body}
              />
            </Col>
          </Row>

          <Row className="mb-3 justify-content-center">
            <Col md={11}>
              <p className="mb-2">Notes</p>
              <Input
                type="textarea"
                name="notes"
                placeholder="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3 justify-content-center">
            <Col md={11}>
              <p className="mb-2">Mode</p>
              <div className="d-flex flex-wrap">
                <Label check className="me-3">
                  Virtual
                </Label>
              </div>
            </Col>
          </Row>
          <div className="text-right d-flex justify-content-end mt-2">
          </div>
        </>
        <div className="d-flex mt-3 justify-content-end align-items-center">
          <Button
            color="primary"
            onClick={handleBookSession}
            disabled={!bookingEnabled}
            className="me-3"
          >
            Book
          </Button>
          <Button type="button" className="btn btn-secondary" onClick={toggle}>Close</Button>
        </div>
      </Col>
    </Row>
  );
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Book Session
        </ModalHeader>
        <ModalBody>
          {renderContent()}
        </ModalBody>
      </Modal>
    </>
  );
};

export default AvailabilityCalendar;
