import React, { useEffect } from "react";
import withRouter from "Components/Common/withRouter";
import { Col, Container, Row } from "reactstrap";

//Import mini card widgets
import MiniCards from "./mini-card";

import WelcomeCard from "./Components/WelcomeCard";
import RecentReviews from "./Components/RecentReviews";
import ReviewStatistics from "./Components/ReviewStatistics";
import { useAppDispatch, useAppSelector } from "slices";
import {
  getUserAnalyticsRating,
  getUserAnalyticsType,
  getUserReviews,
  getUserSessionByMonth,
} from "slices/analytics/thunk";
import SessionHistory from "./SessionHistory";
import { convertRatingToHumanReadable } from "slices/global/utils";
import SessionByMonth from "./Components/SessionByMonth";

const MentorDashboard = () => {
  //meta title
  document.title = "Dashboard | Mentorgain";

  const dispatch = useAppDispatch();

  const { analyticsCount } = useAppSelector((state) => state.Analytics);

  const miniCards = [
    {
      title: "Total sessions",
      iconClass: "bx bx-check-circle",
      text: analyticsCount?.totalSessions,
    },
    {
      title: "Total session hours ",
      iconClass: "bx bx-hourglass",
      text: analyticsCount?.totalHours,
    },
    ...Object.keys(analyticsCount?.ratings || {})
      .slice(0, 4)
      .map((key) => ({
        title: convertRatingToHumanReadable(key),
        iconClass: "bx bx-star",
        text: analyticsCount?.ratings?.[key],
      })),
  ];

  useEffect(() => {
    dispatch(getUserAnalyticsType({ userType: "MENTOR" }));
    dispatch(getUserAnalyticsRating('mentor'));
    dispatch(getUserReviews('mentor'));
    dispatch(getUserSessionByMonth('mentor'));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="Dashboard" /> */}

          <Row>
            <Col xl={4}>
              <WelcomeCard />
              <RecentReviews />
              <ReviewStatistics />
            </Col>

            <Col xl={8}>
              <Row>
                {(miniCards || [])?.map((card: any, key: number) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
                <SessionHistory userType="mentor" />
                <SessionByMonth />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MentorDashboard);
