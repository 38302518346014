import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  resetPasswordMsg: null,
  resetPasswordError: null,
};

const resetInviteSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
      userResetPasswordSuccess(state, action) {
          state.resetPasswordMsg = action.payload
      },
      userResetPasswordError(state, action) {
          state.resetPasswordError = action.payload
      },
      resetResetPassFlag(state) {
        state.resetPasswordMsg = null;
        state.resetPasswordError = null;
      },
  },
});

export const {
  userResetPasswordSuccess,
  userResetPasswordError,
  resetResetPassFlag
} = resetInviteSlice.actions

export default resetInviteSlice.reducer;
