import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserProfile, editUserProfile } from "./reducer";
import { APIClient } from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import {
  setLoggedInCheckDone,
  setLoggedInUserAction,
} from "slices/global/reducer";

const api = new APIClient();

export const editProfile = (values: any) => async (dispatch: any) => {
  try {
    const data = await api.get("api/v1/users", {
      status: "ACTIVE",
    });
    if (data) {
      dispatch(editUserProfile(data));
    }
  } catch (error) {
    toast.error("Error in updating profile");
    // dispatch(userAcceptInviteError(error));
  }
};

export const onEdit =
  (userData: any, history: any) => async (dispatch: any) => {
    try {
      dispatch(getUserProfile(userData));
      history("/profile");
    } catch (error) {}
  };

export const getUser = createAsyncThunk(
  "auth/profile/getUser",
  async (_, { dispatch }) => {
    try {
      const data = await api.get("/api/v1/auth/user", {});
      if (data) {
        dispatch(getUserProfile(data?.user));
        dispatch(setLoggedInUserAction(data));
        return data;
      }
      return null;
    } catch (error) {
      console.error("Error getting user profile:", error);
      dispatch(setLoggedInCheckDone());
      throw error;
    }
  }
);
