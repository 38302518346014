import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { setCurrentChatBoxUserAction } from "./reducer";

const api = new APIClient();

export const getChats = createAsyncThunk(
  "chats/getChats",
  async (_, { dispatch, getState }) => {
    try {
      const data = await api.get("/api/v1/chats");
      const channels = data.channels;
      const currentState: any = getState();
      const currentRoomId = currentState.Chats.currentRoomId;
      if (currentRoomId) {
        const userAChannel = data.channels.find(
          (channel) => channel.user.id === currentRoomId
        );
        if (userAChannel) {
          dispatch(
            setCurrentChatBoxUser({
              name: `${userAChannel.user.firstName} ${userAChannel.user.lastName}`,
              online: userAChannel.user.online,
              roomId: currentRoomId,
              isMentor: userAChannel.user.isMentor,
            })
          );
        }
      }
      return channels;
    } catch (error) {
      toast.error(
        "Error occurred when fetching chat messages. Please try again.",
        { autoClose: 2000 }
      );
      throw error;
    }
  }
);

export const getContacts = createAsyncThunk("chats/getContacts", async () => {
  try {
    const data = await api.get("/api/v1/chats/contacts");
    return data.contacts;
  } catch (error) {
    toast.error("Error occurred when fetching contacts. Please try again.", {
      autoClose: 2000,
    });
    throw error;
  }
});

export const getMessages = createAsyncThunk(
  "chats/getMessages",
  async (receiverId: any) => {
    try {
      const data = await api.get(`/api/v1/chats/${receiverId}`, {
        perPage: 1000,
      });
      return data.chats;
    } catch (error) {
      toast.error("Error occurred when fetching messages. Please try again.", {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const addMessage = createAsyncThunk(
  "chats/addMessage",
  async (data: any) => {
    try {
      const { message, receiverUserId, callback } = data;
      const response = await api.create("/api/v1/chats", {
        receiverUserId,
        message,
      });
      callback("");
      return response;
    } catch (error) {
      toast.error("Error occurred when sending message. Please try again.", {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "chats/deleteMessage",
  async (messageId: any) => {
    try {
      await api.delete(`/api/v1/chats/${messageId}`, {});
      return messageId;
    } catch (error) {
      toast.error("Error occurred when deleting message. Please try again.", {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const setCurrentChatBoxUser =
  (response: any) => async (dispatch: any) => {
    dispatch(setCurrentChatBoxUserAction(response));
  };
